/* .projects-availabiltiy-section .ms-2 {
    box-shadow: 2px 2px 10px rgb(86 85 106 / 20%);
    border-radius: 10px;
    color: #5B5B5B;
    margin-left:0 !important;
}

.projects-availabiltiy-section .ms-2 div {
    margin-top: 1rem;
    padding-right: 3rem;
    padding-left: 0.5rem;
} */
.projects-availabiltiy-section-div{
    background-color: #EEEDEE;
    border-radius: 12px;
    padding: 12px;
    width: 47%;
}