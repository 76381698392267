#wrapper {
  margin: 0 auto;
}
#wrapper .page-editor-main {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  /* align-items: center; */
}
#wrapper .page-editor-main .page-editor-section {
  flex-basis: 70%;
  height: 100%;
  overflow-y: auto;
  padding: 30px 30px 30px 70px;
  /* padding: 30px 5%; */
  /* background-color: #FAFAFA; */
}
#wrapper .page-editor-main .page-editor-section .editor-header {
  width: 100%;
}
#wrapper .page-editor-main .page-editor-section .editor-header button {
  /* width: 50%; */
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
#wrapper .page-editor-main .page-editor-section .editor-header .btn-add-block {
  /* background: linear-gradient(90deg, #1677FB 0%, #8202E6 81.93%); */
  background: #18F;
  box-shadow: 0px 4px 16px rgba(123, 118, 141, 0.1);
  color: white;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  padding: 20px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px !important;
}
#wrapper .page-editor-main .page-editor-section .editor-header .btn-customize-page {
  border-radius: 12px !important;
  display: inline-flex;
  height: 40px;
  padding: 20px 15px;
  align-items: center;
  gap: 5px;
  background: #F3F3F3;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main {
  width: 100%;
  padding: 20px 0;
  margin-top: 10px;
  padding-bottom: 0;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link {
  width: 100%;
  background: #FAFAFA;
  color: #333333;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 16px !important;
  height: 60px;
}

#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link.disabled-link{
  background: #f0f0f0;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button {
  /* background-color: #F7F7F8; */
  background-color: #EEEDEE;
  width: 30px;
  height: 30px;
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 12px !important;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button:hover {
  background-color: #ebebeb;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button:active, #wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button:focus, #wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button:focus-visible {
  border: none;
  box-shadow: none;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link .link-action-button img {
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link-collapsible {
  width: 100%;
  padding-left: 45px;
  padding-bottom: 10px;
  display: none;
}
#wrapper .page-editor-main .page-editor-section::-webkit-scrollbar {
  width: 0.2em;
}
#wrapper .page-editor-main .page-editor-section::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
#wrapper .page-editor-main .right-side-bar {
  height: 100%;
  padding: 20px 15px;
  padding-left: 0;
  padding-bottom: 10px;
  flex-basis: 30%;
}
#wrapper .page-editor-main .right-side-bar .page-preview-link {
  border-radius: 16px;
  /* box-shadow: 0px 0px 20px rgba(88, 86, 107, 0.11); */
  width: 80%;
  padding: 15px 15px;
  font-size: 14px;
  background: #f3f3f3;
  color: #0E123B;
  font-weight: 500;
  width: 337px !important;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile {
  width: 80%;
  height: 75%;
  max-height:600px;
  box-shadow: 0px 11px 53px rgba(6, 4, 30, 0.1);
  border-radius: 35px;
  overflow: hidden;
  width: 338px !important;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  width: 100%;
  height: 100%;
  margin-top: -160%;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner {
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div {
  width: 6%;
  height: 16%;
  background: #333333;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar1 {
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar2 {
  -webkit-transform: rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar3 {
  -webkit-transform: rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar4 {
  -webkit-transform: rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar5 {
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar6 {
  -webkit-transform: rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar7 {
  -webkit-transform: rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar8 {
  -webkit-transform: rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar9 {
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar10 {
  -webkit-transform: rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar11 {
  -webkit-transform: rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile .page-preview-loader div.spinner div.bar12 {
  -webkit-transform: rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile iframe {
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile::-webkit-scrollbar {
  width: 0.5em;
}
#wrapper .page-editor-main .right-side-bar .page-preview-mobile::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
#wrapper .page-editor-main .dashboard-section::-webkit-scrollbar {
  width: 0.5em;
}
#wrapper .page-editor-main .dashboard-section::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

.add-block-modal-body-main {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  /* padding-right: 1rem; */
  padding-top: 0px !important;
}

.add-block-modal-body-main::-webkit-scrollbar {
  width: 0em;
  border-radius: 10px;
}

.add-block-modal-body-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.add-block-modal-body-main::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
}

.new-block-main-div {
  width: 100%;
  flex-wrap: wrap;
  padding:0px 30px;
}

.new-block-div {
  /* max-width: 180px; */
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
  transition: 0.1s all ease;
  -webkit-transition: 0.1s all ease;
  -moz-transition: 0.1s all ease;
  -o-transition: 0.1s all ease;
  -ms-transition: 0.1s all ease;
  flex-basis: 33%;
  /* padding:0 8px; */
}
.new-block-div:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
}

.link-card {
  width: 220px;
  /* height: 180px; */
  /* padding: 10px 10px; */
  padding-left: 5px;
  margin: 0 auto;
}
.link-card .add-image {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 0px 15px rgba(21, 16, 54, 0.1);
  position: absolute;
  z-index: 1;
  margin-top: -10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-card .link-btn {
  width: 220px !important;
  position: relative;
  white-space: normal;
  word-wrap: break-word;
  padding: 1rem 4.5rem;
  font-size: 1.1rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 128, 0.1411764706);
  border: 0;
  transition: opacity 0.3s, background 0.3s;
  border-radius: 0;
  border-radius: 30px;
  padding: 10px;
  min-height: 270px;
  max-height: 270px;
}
.link-card .link-btn .link-btn-image-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 45px;
  max-height: 45px;
  position: absolute;
  top: 50%;
  margin-top: -22.5px;
  margin-left: -4.1rem;
}
.link-card .link-btn .link-img-div2 {
  width: 100%;
  height: 110x;
  background-color: #E9E9E9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.link-card .link-btn .link-img-div2 .link-btn-image2 {
  width: 100%;
  background: url("../img/modals/default-thumbnail.png") no-repeat center;
  background-size: cover;
  /* padding-top: 56.25%; */
  height: 110px;
}
.link-card .link-btn .link-title2 {
  margin: 8px 0;
  font-weight: 600;
  text-align: left;
  margin-bottom: 7px;
  color: #5B5B5B;
  max-height: 45px;
  overflow: hidden;
}
.link-card .link-btn .link-description2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #404762;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  max-height: 30px;
  /* background: red; */
  overflow: hidden;
  overflow-wrap: anywhere;
}
.link-card .link-btn .div-block-3422 {
  display: flex;
  width: 90%;
  margin-top: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #F6F6F6;
  position: absolute;
  bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
}

.up-in-toggle.bg-f1f1f1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 34px;
  color: #5B5B5B;
}
.up-in-toggle.bg-f1f1f1 label {
  display: inline-block;
  width: 60px;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  padding: 6px 16px;
  text-align: center;
  width: 50%;
  float: left;
  transition: all 0.1s ease-in-out;
  z-index: 12 !important;
  position: relative;
}
.up-in-toggle.bg-f1f1f1 label:hover {
  cursor: pointer;
}
#editLinkModal .up-in-toggle.bg-white {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 34px;
  color: #5B5B5B;
}
#editLinkModal .up-in-toggle.bg-white label {
  display: inline-block;
  width: 60px;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  padding: 6px 16px;
  text-align: center;
  width: 50%;
  float: left;
  transition: all 0.1s ease-in-out;
}
#editLinkModal .up-in-toggle.bg-white label:hover {
  cursor: pointer;
}

#editLinkModal .up-in-toggle.bg-white {
  box-shadow: 0px 0px 14px rgba(57, 58, 90, 0.1);
}
#editLinkModal .up-in-toggle.bg-white input {
  clip: rect(0, 0, 0, 0);
  height: 0px;
  width: 0px;
  border: 0;
  overflow: hidden;
  flex-basis: auto;
}
#editLinkModal .up-in-toggle.bg-white input:checked + label {
  background: #f1f1f1;
  font-weight: 500;
}

#editLinkModal .up-in-toggle.bg-white label:first-of-type {
  border-radius: 20px;
}

#editLinkModal .up-in-toggle.bg-white label:last-of-type {
  border-radius: 20px;
}

.up-in-toggle.bg-f1f1f1 input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
/* .up-in-toggle.bg-f1f1f1 input:checked + label {
  background: #FFFFFF;
  font-weight: 500;
} */

.up-in-toggle.bg-f1f1f1 input:checked + label {
  border-radius: 9px;
  background: #fff;
  /* box-shadow: 0px 0px 11px 0px rgba(46, 53, 79, 0.06); */
}

.up-in-toggle.bg-f1f1f1 label:first-of-type {
  border-radius: 20px;
}

.up-in-toggle.bg-f1f1f1 label:last-of-type {
  border-radius: 20px;
}

.product-custom-input {
  border-radius: 12px !important;
  border: 1px solid #E1E1E1 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 5px;
}
.product-custom-input .select {
  position: relative;
  width: 250px;
  border-radius: 12px !important;
}
.product-custom-input .select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product-custom-input .select .product-on-page-toggle:after{
  content: "";
  position: absolute;
  top: 45%;
  margin-left: 15px;
  font-weight: bold;
  width: 6px;
  height: 6px;
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: 0.2s ease;
}
.product-custom-input .select .selectBtn:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 130px;
  font-weight: bold;
  width: 6px;
  height: 6px;
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: 0.2s ease;
}

.product-custom-input .select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}
.product-custom-input .select .selectBtn.toggle:after {
  transform: translateY(-50%) rotate(-135deg);
}
.product-custom-input .select .selectDropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border: 1px solid #E1E1E1 !important;
  z-index: 1;
  background: #fff;
  transform: scale(1, 0);
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  overflow-y: scroll;
  max-height: 200px;
}

/* .product-custom-input .select .selectDropdown::-webkit-scrollbar-track{
  ba
} */

.product-custom-input .select .selectDropdown .option {
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #f8f8f8 !important;
}
.product-custom-input .select .selectDropdown .option:hover {
  background: #f8f8f8;
}
.product-custom-input .select .selectDropdown.toggle {
  visibility: visible;
  transform: scale(1, 1);
}

.link-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
} 

.link-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.link-switch .slider.round {
  border-radius: 9px;
}

.link-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.link-switch .slider.round:before {
  border-radius: 50%;
}

.link-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.link-switch input:checked + .slider {
  background-color: #1188FF;
}

.link-switch input:checked + .slider:before {
  transform: translateX(16px);
}

.customize-page-desktop {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 80vh;
  padding: 0;
}
.customize-page-desktop .submit-buttons-fixed{
  position: absolute;
    bottom: 0;
    background: #FAFAFA;
    width: 100%;
    padding: 15px 30px;
    margin-left: -30px;
    z-index: 5;
}
.customize-page-desktop .left-bar {
  width: 25%;
  height: inherit;
  background-color: #FAFAFA;
  padding: 25px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.customize-page-desktop .left-bar .customize-page-menu-link {
  width: 100%;
  height: 50px;
  padding: 12px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  cursor: pointer;
  margin-bottom: 5px;
}
.customize-page-desktop .left-bar .customize-page-menu-link:hover {
  background-color: #F1F1F1;
}
.customize-page-desktop .left-bar .customize-page-menu-link img {
  box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
  border-radius: 8px;
}
.customize-page-desktop .left-bar .customize-page-menu-link.active {
  background-color: #F1F1F1;
}
.customize-page-desktop .main-section {
  width: 75%;
  height: inherit;
}
.customize-page-desktop .close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 30;
}
#customizePageModal{
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

#customizePageModal .modal-content{
  height: 80vh;
}
.customize-page-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  height: 90vh;
  background-color: white;
  padding: 0;
}
.customize-page-mobile .close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
}

.customize-page-main {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  padding-bottom: 0;
}
.newpadding{  padding: 50px 30px;}
.mt10-u{margin-top: 10px !important; margin-bottom: 0 !important;}
.customize-page-main .input-form-container{
  background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(64, 62, 85, 0.1);
border-radius: 16px;
}
.customize-page-main label {
  font-weight: 500 !important;
}
.customize-page-main .drag-icon {
  margin: 6px;
  width: 48px;
  height: 48px;
  background-color: #F7F7F8 !important;
  color: #1F1F1F;
  display: flex;
  justify-content: center;
  border-radius: 12px !important;
  align-items: center;
}
.customize-page-main .input-group-text {
  background-color: #F7F7F8 !important;
}
.customize-page-main .input-group-text b{
  font-weight: 500 !important;
}
.customize-page-main section {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 60px;
}
.customize-page-main section::-webkit-scrollbar {
  width: 0em;
}
.customize-page-main section::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
#customizePageProfile .zaap-form-control {
  background: #EEEDEE;
  border-radius: 9px !important;
  height: 30px;
  color: #5B5B5B !important;
  font-weight: 500;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 12px;
}
.customize-page-main .user-pp {
  border-radius: 70px;
  border: 1.5px dashed #6B6B6B;
  padding: 3px;
  width: 74px;
  height: 74px;
  cursor: pointer;
}
.upload-pp-div{
  display: flex;
    gap: 16px;
}
.upload-pp-btn{
  display: inline-flex;
  min-height: 30px;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #F3F3F3;
  border: none;
  font-size: 12px;
  font-weight: 500;
}
.upload-pp-text{
  color: #333;
  font-size: 14px;
  font-weight: 500;
}
.upload-pp{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
}
.customize-page-main .theme-main-right {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
/* .customize-page-main .theme-main-right .theme-selection-div {
  flex-basis: 20%;
} */
.customize-page-main .theme-selection-div {
  padding: 5px;
}
.customize-page-main .theme-selection-indiv {
  /* background-color: #F1F1F1; */
  border-radius: 12px;
  /* height: 250px; */
  padding: 0;
  border: 3px solid #fff !important;
  width: 129px !important;
  height: 54px !important; 
}
.customize-page-main .themes-selection div div span {
  display: inline-flex !important;
  padding: 5px 36px 5px 5px !important;
  align-items: center !important;
  gap: 10px !important;
  border-radius: 14px !important;
  background: #F2F2F2 !important;
  color: #333 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 106.1% !important;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
.customize-page-main .themes-selection div span img {
  width: 40px !important;
  border-radius: 9px;
}
.customize-page-main .themes-selection div span small {
  margin-top: 4px;
}
.customize-page-main .theme-selection-indiv.selected-theme {
  border-radius: 15px !important;
  border: 1px solid #4184D6 !important;
}
.customize-page-main .custom-domain-input-group {
  width: 100%;
  padding: 7px 15px;
  background-color: #F7F7F8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customize-page-main .custom-domain-input-group button {
  width: auto !important;
}
.fav-uplod {
  height: 34px !important;
  width: 34px;
}
.customize-page-main .dropzone-wrapper5 .dropzone-desc {
  top: 15% !important;
}
.customize-page-main .integration-card {
  max-width: 370px;
  width: 100%;
  min-height: 196px;
  border-radius: 16px;
  background: #FAFAFA;
  padding: 16px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}
.integration-btns{
  display: flex;
  height: 34px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 11px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 44px;
}
.integration-btn-primary {
  background: #FF1451;
  color: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(49, 48, 67, 0.10);

}
.integration-btn-grey {
  background: #F1F1F1;
  color: #000;
}
.customize-page-main .integration-card .google-analytics-btn {
  background-color: #F9AB00;
}
.customize-page-main .integration-card .facebook-pixel-btn {
  background-color: #1877F2;
}
.customize-page-main .integration-card .mailchimp-btn {
  background-color: #FFDC00;
}
.customize-page-main .integration-card .convertkit-btn {
  background-color: #FC6B72;
}
.customize-page-main .integration-card .sendinblue-btn {
  background-color: #0092FF;
}
.customize-page-main .customize-page-main.accordion-body section {
  padding-bottom: 10px !important;
}

.marquee {
  width: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee p {
  display: inline-block;
  -webkit-animation: marquee 7s ease-in-out alternate infinite;
          animation: marquee 7s ease-in-out alternate infinite;
}

.marquee-reverse {
  width: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee-reverse p {
  display: inline-block;
  transform: translate(-100%, 0);
  -webkit-animation: marquee-reverse 7s ease-in-out alternate infinite;
          animation: marquee-reverse 7s ease-in-out alternate infinite;
}

@-webkit-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@-webkit-keyframes marquee-reverse {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes marquee-reverse {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}/*# sourceMappingURL=project.css.map */

.zaap_form_control{
  
}
.customize-page-mobile .accordion-button{
  background-color: transparent !important;
}


.profile-style-div.active {
  border: 3px solid rgb(118, 186, 255) !important;
}
.profile-style-div {
  border-radius: 24px;
  cursor: pointer;
  border: 3px solid transparent;
  overflow: hidden;
}
.link-style-div.active {
  border: 3px solid rgb(118, 186, 255) !important;
}
.link-style-div {
  border-radius: 12px;
  cursor: pointer;
  border: 3px solid transparent;
  overflow: hidden;
}
.banner-upload-btn, .banner-upload-btn2 {
  color: black;
  font-weight: 600;
  border: 1px solid rgba(14, 18, 59, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  height: auto;
  font-size: 14px;
  width: 150px !important;
  cursor: pointer !important;
}
.banner-upload-btn2:disabled{
  background-color: #cdcdcd !important;
  cursor: not-allowed !important;
}


/* /* #createcontactModal input[type="checkbox"]{
  
}

 input[type="checkbox"]:checked {
  display:  ;
  accent-color: #00c9ff !important;
  color: white !important;
} 

 #createcontactModal input[type="checkbox"]:checked::after {
  
  color: white !important;
  
  font-size: 12px;
  box-shadow: none !important;
} 
#createcontactModal {
  border: 1px solid #cccfdb;
  border-radius: 2px;
} 
#createcontactModal input[type="checkbox"]{
 display: inline-block;
 position: relative;
 color: #0087ca;
}

#createcontactModal input[type="checkbox"]:checked:after {
 content: '';
 position: absolute;
 width: 100%;
 transform: scaleX(0);
 height: 2px;
 bottom: 0;
 left: 0;
 background-color: #00c9ff;
 transform-origin: bottom right;
 transition: transform 0.25s ease-out;
}

#createcontactModal input[type="checkbox"]:hover:after {
 transform: scaleX(1);
 transform-origin: bottom left;
}
--- */

#createcontactModal input[type="checkbox"] {
  display: none;
}

#createcontactModal #label {
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid grey;
  border-radius: 4px;
  cursor: pointer;
}

#createcontactModal input[type="checkbox"] + #label {
  background-color: #ffffff;
  border: none;
}

#createcontactModal input[type="checkbox"]:checked + #label {
  /* background-color: #00c9ff; */
  border: none;
}

#createcontactModal input[type="checkbox"] + #label:after {
  content: url('../img/contact-block-unchecked.svg');
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#createcontactModal input[type="checkbox"]:checked + #label:after {
  content: url('../img/contact-block-checked.svg');
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */ 
}
.contact-block-fields{
  border-radius: 10px;
}
#createcontactModal .contact-toggle{
  background: #f3f3f3 !important;
}
.upgradenewsmodal{
  padding-top: 18px;
  padding-left: 14px;
  padding-right: 55px;
  padding-bottom: 18px;
} 
.newsletter-modalheading{
  font-weight:bolder !important;
  font-size: 26px;
}
.newsletter-modaltext{
  font-size: 18px;
  font-weight: 500 !important;
  color:#5B5B5B !important;
  padding: 0% !important;
}
.editor-modaltext{
  font-size: 20px;
  font-weight: 500 !important;
  color:#5B5B5B !important;
  padding: 0% !important;
}
.news-modal-btn{
  font-size: 17px;
  font-weight:550 !important;
  padding-left: 15px;
  padding-right: 15px;
}
.schedule-toggle{
  background: none !important;
}
.newsletter-btn-semi-rounded{
  border-radius: 10px !important;
  padding: 5px 2px !important;
  font-size: 14px !important;
  color: #6B6B6B !important;
}
.newsletter-btn-semi-rounded.channels-toggle{
  padding: 5px 10px !important;
}
.schedule-time::after {
  display: none;
}
.domain-enabled{
  background: transparent;
  height: 50px;
  border-radius: 6.458px;
  background: #FFF;
  box-shadow: 0px 0px 3.229px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  width: 100%;
  padding: 18px 10px;
  align-items: center;
  margin-right: 20px;
  font-size: 12px;
  max-width: 504px;

}
.domain-btns-desktop {
  display: flex;
}
.domain-options {
  align-items: center;
  gap: 20px;
}
.domain-btns-mobile {
  display: none;
}
.disconnect-domain-btn{
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #FAFAFA;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.custom-domain-input-group.domain-enabled p{
  font-size: 12px;
}
.link-thumbnail-upload-dropzone{
  padding:0px 15px !important;
  border-radius: 20px;
  border: 1px dashed #A0A0A0;
  display: flex;
  align-items: center;
}

.link-thumbnail-upload-dropzone span{
  color: #5B5B5B;
  font-weight: 500;
  padding-left: 15px;
}
.int-3-b{
  width:100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cent-cont-u{
  max-width:780px;
  width:100%;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.int-3-u{
  display: flex;
  justify-content: center;
  align-items: center;
}
.int-u{
  display: flex;
  align-items: center;
  gap: 25px;
  /* justify-content: center; */
  flex-wrap: wrap;
  max-width: 780px;
  margin-top: 30px;
  text-align: left;
}
.toggletext{
  color: #333 !important;
  text-align: center !important;
  font-family: GT Walsheim Pro !important;
  font-style: normal;
  font-weight: 500;
}

@media (max-width:991px) {
  .int-u{
    grid-template-columns: auto auto;
  }
}
/* @media (max-width:768px) {
  .int-u{
    display: flex;
  }
  .customize-page-main .integration-card{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 16px;
    background: #EEEDEE;
  }
  .customize-page-main .integration-card h5{
    width: 100%;
    font-size:12px!important;
    font-weight: 500;
    display: inline-flex;
    justify-content: start;
    margin-left: 12px;
  }
  .customize-page-main .integration-card h5 br {
    display: none;
  }
  .customize-page-main .integration-card button{
    display: inline-flex !important;
    height: 30px !important;
    padding: 10px 13px !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .customize-page-main .integration-card .integration-card-img-div{
    padding: 21px;
  }

  .customize-page-main .integration-card label{
    display: none;
  }
} */
.add-block--modal-heading {
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  text-align: start;
  line-height: normal;
}

#wrapper .page-editor-main .page-editor-section .editor-links-main .up-in-toggle input {
  flex-basis: 0 !important;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .up-in-toggle {
  width: 74px;
  height: 30px;
  border-radius: 10px !important;
  padding: 13px 3px !important;
  background: #EEEDEE ;
  margin-right: 12px;
}
#wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link.disabled-link .link-action-button, #wrapper .page-editor-main .page-editor-section .editor-links-main .editor-link.disabled-link .up-in-toggle {
  background-color: #F7F7F8 !important;
} 
#wrapper .page-editor-main .page-editor-section .editor-links-main .up-in-toggle.bg-f1f1f1 input:checked + label {
  display: inline-flex;
  justify-content: center;
  border-radius: 7px !important;
}
.integration-card-img-div{
  border-Radius: 11px;
  background: #FFF1D2;
   width: 43px;
  height: 43px;
  display: inline-flex;
  justify-Content: center;
  align-Items: center;
  box-shadow: 0px 4px 20px 0px rgba(103, 101, 122, 0.19);
}
.integration-card h5{
  font-size: 18px;
  font-weight: 700;
}
.integration-card .text-lightgray{
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
}
.editor-link .up-in-toggle.bg-f1f1f1 input{
  position: absolute;
  top: 0;
  left: 0;
}
.custome-box-u{
  width: 100px !important;
  height: 127px !important;
  padding: 7px !important;
  border-radius: 12px !important;
  background: #F6F6F6;
}
.box-area-u{
  display: flex;
  flex-direction: column;
}
.custome-gred-box{
  position: relative !important;
  width: 100% !important;
  height: 75px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customize-page-main .themes-selection div span div .gred-theme-bg{
  position: absolute !important;
  width: 75px !important;
  filter: blur(10px);
}
.customize-page-main .themes-selection div span div .gred-theme-main{
  position: absolute !important;
  width: 73px !important;
  z-index: 5;
}
.theme-text-u{
  height: fit-content !important;
  width: 100% !important;
  color: #333;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 14.854px */
  margin-top: 5px;
}
.font-12{
  font-size: 12px!important;
}
.text-grey-u{
  color: rgba(91, 91, 91, 0.50)!important;
}
.padd-u{
  padding: 5px;
}

.icon-shadow{
  box-shadow: 0px 4px 20px 0px rgba(103, 101, 122, 0.19);
  width: fit-content;
  border-radius: 11px;
}
@media (max-width: 768px) {
    .icon-shadow>img{
      width: 30px;
    }
    .integration-card>h5{
      margin-top: 0 !important;
    }
}
.switcher-u{
  width: 74px !important;
  height: 30px !important;
  background-color: #EEEDEE !important;
  border-radius: 9px !important;
  padding: 3px !important;
  position: relative;
}
.toggle_switcher{
  width: 34px;
  height: 24px;
  border-radius: 7px;
  background: #FFF;
  position: absolute;
  right: 3px;
  transition: 0.5s ease;
}
.swtichActive{
  transform: translateX(-100%);
}
.redeye{
  filter: grayscale(100%) invert(14%) sepia(97%) saturate(7500%) hue-rotate(5deg);
}
.social-icon-u{
  margin-right: 6px;
  display: flex;
  align-items: center;
}
.social-icon-u svg{
  max-width: 35px;
  min-width: 20px;
  min-height: 20px;
  max-height: 22px;
}
.social-icon-u:not(.youtube) svg path{
  fill:rgb(48,58,71);
}
.link_added:not(.youtube) .social-icon-u svg path{
  fill:#fff;
}
.new-input-area{
  border-radius: 9px;
  background: #EEEDEE;
  padding: 3px 3px 3px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.link_added{
  background-color: #1188FF;
}
.link_added span{
  color: rgba(255, 255, 255, 0.50) !important;
}
/* .link_added:not(.youtube) svg path{
  stroke: white;
} */

.your-social-area{
  width: 100%;
}
.your-social-area >span{
  color: rgba(91, 91, 91, 0.50);
  font-size: 12px;
  font-weight: 500;
}
.your-social-area input{
  /* width: 70%; */
  padding: 0;
  padding-left: 3px;
  color: #5B5B5B !important;
  font-size: 12px;
  font-weight: 500;
}
.link_added input{
  color: #fff !important;
}
.add-btn-u{
  width: 47px;
  height: 24px;
  border-radius: 6px;
  background: #18F;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  display: none;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  padding-top: 3px;
}
.addbtn-act{
  display: flex;
}
.cross-u{
  background-color: transparent;
  border: none;
  outline: none;
  width: fit-content;
  height: fit-content;
  padding: 0;
  display: none;
}
.crossact{
  display: block;
}
.Default_inp:-webkit-autofill,
.Default_inp:-webkit-autofill:hover, 
.Default_inp:-webkit-autofill:focus, 
.Default_inp:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #EEEDEE inset !important;
    /* -webkit-text-fill-color: white !important; */
    
}
.link_added input:-webkit-autofill,
.link_added input:-webkit-autofill:hover, 
.link_added input:-webkit-autofill:focus, 
.link_added input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #18F inset !important;
    -webkit-text-fill-color: white !important;
    
}
.link-style-div-content{
  background:black;
  width:100%;
  height:100%;
  border-radius:12px;
}
.link-style-div-content-gradient{
  background:linear-gradient(#000000 0%, #FAFAFA 59.86%);
}
.social-icon-u svg {
  min-height: 22px;
}
.link_added:not(.youtube) .social-icon-u svg path{
  fill: white !important;
}
.product-link-action-button{
  background-color: #EEEDEE !important;
  display: flex !important;
  width: 30px;
  height: 30px;
  /* padding: 11px 15px; */
  justify-content: center !important;
  align-items: center !important;
  gap: 6px;
  border-radius: 9px !important;
}
.fs-14px{
  font-size: 14px !important;
}
.color-5b5b5b{
  color: #5b5b5b !important;
}
.cursor-grab:active {
  cursor: grabbing;
}

.cursor-grab {
  cursor: grab;
}
.mt-27 {
  margin-top: 27px !important;
}
.fs-16 {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.rotated-alignment-icon {
  transform: rotate(270deg);
}
.name-icon {
  width: 30px;
  height: 30px;
  background-color: #18f;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 119.1%; /* 14.292px */
  padding: 8px 0;
  border-radius: 50%;
  text-transform: uppercase;
  text-align: center;
}
.border-grey {
  border-radius: 12px;
  background: #EBEBEB;
  height: 1px;
}
.box-primary {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #FF1451;
}
.blue-shadow-button {
  height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  background: #18F;
  box-shadow: 0px 4px 8px 0px rgba(17, 136, 255, 0.30);
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  border: none;
  text-align: center;
;}
.blue-shadow-button:hover {
  color: white !important;
  opacity: 0.8;
}
.affiliate-payout-modal .modal-dialog{
  max-width: 400px;
}
.affiliate-payout-text {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 114.1%; /* 15.974px */
  letter-spacing: -0.21px;
}
.affiliate-payout-price {
  background: #EEEDEE;
  display: flex;
  max-width: 400px;
  height: 205px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 30px;
  font-weight: 500;
  line-height: 114.1%; /* 34.23px */
  letter-spacing: -0.45px;
}
.affiliate-payout-heading {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 114.1%; /* 25.102px */
  letter-spacing: -0.33px;
}
.settings-button-primary:disabled {
  opacity: 0.7;
}
.customize-modal-content{
  background: #fff;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  border-radius: 20px;
}
#customizePageModal>.modal-content {
  height: 90vh !important;
  overflow: visible !important;
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.unsave_changes_container{
  min-height: 69px;
  border-radius: 19px;
  background: #101010;
  padding: 0px 18px 0px 27px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-size: 22px;
  font-weight: 500;
  line-height: 106.1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.revert_btn {
  border-radius: 9.6px;
  background: #5E5E5E;
  box-shadow: 0px 4.8px 12.6px 0px rgba(94, 94, 94, 0.35);
  display: inline-flex;
  height: 36px;
  padding: 9.6px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #FFF;
  font-size: 14.4px;
  font-weight: 500;
  border: none !important;
  transition: 0.5s ease;
}
.revert_btn:hover{
  background-color: red;
}
.unsave_changes_container.highlight {
  background: #FF4D14;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
}
.unsave_changes_container.highlight {
  animation: shake 1s ease infinite;
}
@keyframes shake {
	0%, 100% {transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
	20%, 40%, 60%, 80% {transform: translateX(10px);}
}
.content-dropdown {
  display: flex;
  height: 30px;
  width: fit-content;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  border: none;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%; /* 12.732px */
  cursor: pointer;
  outline: none;
}
.switch-btns {
  display: flex;
  max-width: 130px;
  height: 40px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: #F7F7F8;
  position: relative;
  color: #5B5B5B;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 106.1%;
  letter-spacing: -0.21px;
}
.switch-btns input {
  flex-basis: 0;
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-btns input:checked + label {
  background: #FFF;
}
.switch-btns label {
  border-radius: 7px;
  padding: 8px 15px;
  height: 30px;
  width: 65px;
  cursor: pointer;
}
.content-dropdown-menu {
  padding: 3px;
  border-radius: 9px;
  width: fit-content;
  min-width: auto;
}
.content-dropdown::after {
  content: none;
}
.content-dropdown-item {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%;
  cursor: pointer;
  border-radius: 9px;
  padding: .25rem 10px;
}
.social-icon-u #drag {
  display: flex;
  align-items: center;
}
.zaap-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  border-radius: 9px;
  background: #F3F3F3;
  cursor: pointer;
  color: rgba(91, 91, 91, 0.50);
  font-size: 12px;
  font-weight: 500;
  line-height: 106.1%; /* 12.732px */
  letter-spacing: -0.18px;
  border: none;
  position: relative;
}
.hidden-file-upload {
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
}
.remove-logo-cont {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #0E123B;
  font-size: 14px;
  font-weight: 500;
  line-height: 114.1%; /* 15.974px */
  border-radius: 16px;
  background: #F3F3F3;
  margin-top: 24px;
  max-width: 338px;
}
.gradient-eye-icon {
  position: relative;
}
.gradient-eye-icon img:first-child {
  position: absolute;
  filter: blur(4px);
}
.toggle-input-checkbox {
  background: #f3f3f3;
    border-radius: 9px;
    height: 30px;
    padding: 2px;
    width: 100%;
    align-items: center;
    color: #473e43;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -.18px;
    line-height: 125%;
    text-align: center;
}
.toggle-input-checkbox input {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.toggle-input-checkbox label {
  cursor: pointer;
  border-radius: 8px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.toggle-input-checkbox input:checked + label {
  background: #FDFDFD;
  box-shadow: 0px 0px 11px 0px rgba(46, 53, 79, 0.06);
}
.toggle-input-checkbox input:checked + .dark {
  background: #121111 !important;
  color: #FFF !important;
}
.box-gray {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: #F3F3F3;
}
.grey-btn{
  display: inline-flex;
  height: 30px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.10);
  color: #333;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.blue-btn{
  display: inline-flex;
  height: 30px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: #18F;
  box-shadow: 0px 4px 8px 0px rgba(17, 136, 255, 0.30);
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
  border: none;
  transition: box-shadow 0.5s ease;
}
.blue-btn:hover{
  color: #fff;
}
.fs-12px {
  font-size: 12px;
}

.link_added.add_inp-tiktok .social-icon svg path,
.link_added.add_inp-youtube .social-icon svg path,
.link_added.add_inp-twitch .social-icon svg path{
  fill: white;
}
.link_added.add_inp-twitter .social-icon svg path,
.link_added.add_inp-instagram .social-icon svg path{
  stroke: white;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.custom-alert-message {
  position: fixed;
  bottom: 20px;
  right: -400px;
  display: flex;
  align-items: flex-start;
  padding: 21px 17px 21px 23px;
  background: #2E2E2E;
  border-radius: 14px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.50);
  animation: slideIn 0.5s ease forwards, slideOut 0.5s ease 7.5s forwards;
  color: white;
  z-index: 9999;
  gap: 13px;
  max-width: 630px;
  justify-content: space-between;
  line-height: 1;
}
.custom-alert-message .heading{
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
}
.custom-alert-message .description{
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
}
.custom-alert-message .sub-description{
  color: #B9B9B9;
  font-size: 14px;
  font-weight: 500;
}
.alert-icon svg {
  width: 24px;
  height: 24px;
}
.alert-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-top: 3px;
  text-transform: capitalize;
}

.alert-actions {
  display: flex;
  gap: 10px;
}

.alert-btn {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: 0px 4.8px 12.6px 0px rgba(94, 94, 94, 0.15);
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.7s ease;
  gap: 3px;
}
.alert-btn svg {
  width: 16px;
  height: 16px;
}
.alert-btn:hover {
  opacity: 0.7;
  color: white;
}
.primary-btn {
  background: #18F;
  border-radius: 9.6px;
  box-shadow: 0px 4.8px 12.6px 0px rgba(17, 136, 255, 0.36);
}

.secondary-btn {
  background: #5E5E5E;
  border-radius: 9.6px;
}

.alert-close-btn {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 12px;
  margin: 0;
  padding: 0;
  line-height: 1;
}

@keyframes slideIn {
  from {
    right: -400px; /* Start off screen */
    opacity: 0;
  }
  to {
    right: 20px; /* End at the final position */
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    right: 20px; /* Start from current position */
    opacity: 1;
  }
  to {
    right: -400px; /* Slide out of the screen */
    opacity: 0;
  }
}
.left-side-bar .sidebar-sub-menu{
  display: none;
  max-width: 206px;
  border-left: 1px solid;
  border-image: linear-gradient(176deg, #EEEDEE 0%, #FAFAFA 100%) 1;
  background-color: transparent;
  flex-direction: column;
  list-style: none;
  padding: 10px;
  margin-left: 16px;
}
.left-side-bar .sidebar-sub-menu.show{
  display: block;
}
.left-side-bar .sidebar-sub-menu.closed{
  position: absolute;
  margin-top: 0;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}
.left-sub-menu-item{
  display: flex;
  align-items: center;
  gap: 11px;
  border-radius: 9px;
  max-width: 192px;
  width: 100%;
  height: 30px;
  padding: 7px 8px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.left-sub-menu-item.active,
.left-sub-menu-item:hover {
  background-color: #EEEDEE;
  color: #333;
}
.left-side-bar.closed .sidebar-sub-menu:hover,
.left-side-bar.closed .sidebar-sub-menu-main:hover ~ .sidebar-sub-menu{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  position: absolute;
  left: 47px;
  background: #FAFAFA;
  z-index: 1;
  border-radius: 12px;
  min-width: 200px;
  margin-top: -38px;
  display: block;
}
.left-side-bar.closed .sidebar-sub-menu{
  opacity: 0;
}
.sidebar-sub-menu-main {
  cursor: pointer;
}
.left-side-bar:has(.left-sub-menu-item.active) .sidebar-sub-menu-main {
  background-color: #EEEDEE;
  color: #333;
}
.integration-heading {
  color: #03021C;
  font-size: 18px;
  font-weight: 500;
}
.blue-badge {
  display: flex;
  height: 13px;
  padding: 3px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.20);
}
.btn-gray,.read-docs {
  display: inline-flex;
  height: 30px;
  padding: 9.6px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 9.6px;
  background: #5E5E5E;
  box-shadow: 0px 4.8px 12.6px 0px rgba(94, 94, 94, 0.15);
  text-decoration: none;
  transition: 0.5s ease;
  border: none;
}
.btn-gray,.read-docs:hover {
  color: #fff;
  box-shadow: 0px 4.8px 12.6px 0px rgba(94, 94, 94, 0.55);
}
.int-btn-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.blue-btn:hover {
  box-shadow: 0px 4px 8px 0px rgba(17, 136, 255, 0.60);
}
.new-primary-btn {
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 9px;
  background: #FF1451;
  box-shadow: 0px 4px 15px 0px rgba(255, 20, 81, 0.32);
  border: none;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  transition: box-shadow 0.5s ease;
}
.new-primary-btn:hover {
  box-shadow: 0px 4px 15px 0px rgba(255, 20, 81, 0.62);
}
.primary-badge {
  display: flex;
  height: 13px;
  padding: 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.20);
}
.btn-gray .white-gear {
  width: 14px;
  height: 16px;
  filter: invert(1) brightness(100);
}
.integrations-toggle-tab {
  max-width: 500px;
  min-height: 36px;
  margin-top: 30px;
}
.integrations-toggle-tab label {
  width: 100%;
}
.integrations-toggle-tab input:checked + label {
  background-color: #18f;
  color: white;
}
.integrations-tab-dropdown {
  border: 3px solid rgba(243, 243, 243, 0.55);  
  border-radius: 9px;
  background: #18F;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4.5px 10px;
  max-width: fit-content;
  margin-top: 30px;
  cursor: pointer;
  display: none;
}

.integrations-tab-dropdown.show img {
  transform: rotate(180deg);
}
.integrations-tab-dropdown-menu  li a:hover {
  background-color: #F3F3F3 !important;
  color: #000 !important;
}
.integrations-tab-dropdown img {
  filter: invert(1) brightness(100);
  transition: 0.3s ease;
  transform: rotate(0deg);
}
.integrations-tab-dropdown::after{
  display: none;
}
.fs-22px {
  font-size: 22px !important;
}
.connect-domain-container {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  max-width: 808px;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  padding: 30px 24px 30px 30px;
  /* min-width: 418px; */
}
.connect-domain-container .title {
  color: rgba(3, 2, 28, 0.50);
  font-size: 18px;
  font-weight: 500;
}
.connected-domain-container {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 37px;
}
.dns-status-main {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 15px 15px;
  border-radius: 6.458px;
  background: rgba(12, 120, 247, 0.13);
  box-shadow: 0px 0px 3.229px 0px rgba(0, 0, 0, 0.07);
  font-weight: 500;
  color: #434343;
}
.dns-status-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  color: #434343;
  font-size: 14px;
  font-weight: 500;
}
.dns-status-desc {
  font-size: 12px;
}
.dns-status-desc {
  font-size: 12px;
  color: #434343;
  font-size: 12.27px;
  font-weight: 500;
  margin-left: 35px;
}

.dns-container {
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

.dns-table {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.dns-table-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 6px 6px 0px 0px;
}

.dns-header-cell {
  padding: 12px 16px;
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.dns-table-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid rgba(107, 107, 107, 0.10);
  border-bottom: 1px solid rgba(107, 107, 107, 0.10);
  background: #FFF;
}

.dns-table-cell {
  padding: 12px 16px;
  color: #434343;
  font-size: 12.698px;
  font-weight: 500;
  letter-spacing: -0.127px;
  text-align: center;
}

.dns-table-row:hover {
  background-color: #f8f9fa;
  transition: background-color 0.2s;
}
.dns-sec {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dark-text {
  color: #03021C !important;
}
#wrapper .domain-section{
  max-width: 800px;
  padding: 2px;
  overflow-x: auto;
}
.gray-hover:hover {
  background-color: #f3f3f3 !important;
  color: #000 !important;
}
.domain-status-icon {
  display: inline-flex;
  padding: 6px 11px 7px 12px;
  justify-content: center;
  align-items: center;
  background-color: #1A1A1C;
  border-radius: 15px;
}
.domain-status-icon.not_verified svg path{
  fill: #bdbdbd;
}
.domain-status-icon.verified svg path{
  fill: #34C759;
}
.domain-status-icon.pending svg path{
  fill: #FFC700;
}
body:has(.new-confirmation-modal.show) > .modal-backdrop.show {
  opacity: 0.8 !important;
}
.domain-section-container {
  overflow: auto;
  padding-bottom: 40px;
}
@media (max-width:1022px){
  .domain-section-container {
    padding-left: 135px;
    padding-right: 70px;

  }
}
@media (max-width:766px){
  .domain-section-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.filter-brightness-0 {
  filter: brightness(0);
}