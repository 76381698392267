@font-face {
    font-family: 'GT Walsheim Pro';
    src: local('GT Walsheim Pro Regular'), local('GT-Walsheim-Pro-Regular'),
        url('../fonts/GTWalsheimPro-Regular.woff2') format('woff2'),
        url('../fonts/GTWalsheimPro-Regular.woff') format('woff'),
        url('../fonts/GTWalsheimPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'GT Walsheim Pro';
    src: local('GT Walsheim Pro Medium'), local('GT-Walsheim-Pro-Medium'),
        url('../fonts/GTWalsheimPro-Medium.woff2') format('woff2'),
        url('../fonts/GTWalsheimPro-Medium.woff') format('woff'),
        url('../fonts/GTWalsheimPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'GT Walsheim Pro';
    src: local('GT Walsheim Pro Bold'), local('GT-Walsheim-Pro-Bold'),
        url('../fonts/GTWalsheimPro-Bold.woff2') format('woff2'),
        url('../fonts/GTWalsheimPro-Bold.woff') format('woff'),
        url('../fonts/GTWalsheimPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'GT Walsheim Pro';
    src: local('GT Walsheim Pro Black'), local('GT-Walsheim-Pro-Black'),
        url('../fonts/GTWalsheimPro-Black.woff2') format('woff2'),
        url('../fonts/GTWalsheimPro-Black.woff') format('woff'),
        url('../fonts/GTWalsheimPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
*{
    font-family: 'GT Walsheim Pro';
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
