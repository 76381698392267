@import "colors";

#wrapper {

    .monetization-main {
        width: 100%;
        height: calc(100vh - 70px);
        display: flex;
        align-items: center;

        .left-side-bar {
            background-color: #FAFAFA;
            height: 100%;
            padding: 20px 15px;
            padding-bottom: 10px;
            flex-basis: 25%;

            .left-menu-item {
                width: 100%;
                border-radius: 15px;
                padding: 15px 20px;
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                text-decoration: none;

                p {
                    color: #333333;
                    font-weight: bold;
                    font-size: 18px;
                    padding-top: 2px;
                }

                &:hover {
                    text-decoration: none;
                    background-color: #EEEDEE;
                }
            }

            .left-menu-item.active {
                background-color: #EEEDEE;
            }
        }

        .monetization-section {
            flex-basis: 75%;
            height: 100%;
            overflow-y: auto;
            // background-color: #118EFB;
            padding: 30px;
            padding-left: 50px;

            .section-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .section-main {
                width: 100%;

                .search-bar {
                    // background-color: red;
                    flex: 3;

                    input {
                        font-weight: 500 !important;

                        &::placeholder {
                            font-weight: 500 !important;
                        }
                    }
                }

                .filter-dropdown {
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 10px;

                    &::after {
                        display: none !important;
                    }

                    &:hover {
                        background-color: #F3F3F3 !important;
                    }
                }

                .table-action-dropdown {
                    &::after {
                        display: none !important;
                    }
                }

                .filter-dropdown.show {
                    background-color: #F3F3F3 !important;
                }

                .filter-dropdown-menu {
                    li {
                        a {
                            &:hover {
                                background-color: #F3F3F3 !important;
                                color: inherit !important;
                            }

                            span {
                                img {
                                    opacity: 0;
                                }
                            }

                        }

                        a.active {
                            background-color: #F3F3F3 !important;
                            color: #4397F7 !important;

                            span {
                                font-size: 16px !important;

                                img {
                                    opacity: 1;
                                    margin-top: -4px !important;
                                }
                            }
                        }
                    }
                }

                table {
                    thead {
                        border-top: 1px solid #E8E9EA;
                        border-bottom: 1px solid #E8E9EA;

                        th {
                            font-weight: 500;
                            color: $theme-text-color;
                        }
                    }

                    tbody {
                        border-top: none !important;
                        border-bottom: 1px solid #E8E9EA;

                        tr {
                            border: none !important;
                            border-bottom: 1px solid #E8E9EA !important;

                            td {
                                vertical-align: middle;
                                border: none !important;
                                font-weight: 500;

                                .subscriber-name-icon {
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 500;
                                    background-color: #03ACFF;
                                    color: white;
                                    border-radius: 50px;
                                }

                                .table-action-dropdown {
                                    cursor: pointer;
                                    padding: 10px;
                                }

                                .badge {
                                    padding: 10px 15px;
                                    border-radius: 10px;
                                    font-weight: 500;
                                    font-size: 16px;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                .badge-subscribed {
                                    color: #9B1DFF;
                                    background-color: #F2EEFF;

                                    svg {
                                        fill: #9B1DFF;
                                    }
                                }

                                .badge-unsubscribed {
                                    color: #FF1D53;
                                    background-color: #FFEEF1;

                                    svg {
                                        fill: #FF1D53;
                                    }
                                }

                                .badge-customer {
                                    color: #FFC01D;
                                    background-color: #FFFAEE;

                                    svg {
                                        fill: #FFC01D;
                                    }
                                }
                            }
                        }
                    }

                    .font-2x {
                        font-size: 30px;
                    }
                }

                .table>:not(caption)>*>* {
                    border: none !important;
                }

                .section-footer {
                    width: 100%;
                    padding: 10px 0;

                    .table-pagination {
                        display: flex;
                        align-items: center;
                        justify-content: end;

                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 35px;
                            height: 35px;
                            text-align: center;
                            background-color: #EEEDEE;
                            color: $theme-text-lightcolor;
                            border-radius: 10px !important;
                            margin-right: 5px;
                            font-size: 16px;
                            font-weight: 600;

                            &:hover {
                                background-color: #212322 !important;
                                color: white !important;
                            }
                        }

                        button.active {
                            background-color: #212322 !important;
                            color: white !important;
                        }
                    }
                }
            }
        }

        .monetization-section::-webkit-scrollbar {
            width: 0.5em;
        }

        .monetization-section::-webkit-scrollbar-thumb {
            background-color: rgba(128, 128, 128, 0.5);
            border-radius: 5px;
        }

    }
}

#newProductModal {
    .modal-content {
        width: 522px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .product-header {
            border-bottom: 1px solid $border-color ;

            .close {
                font-size: 2.5rem !important;
            }
        }
        .product-dropzone{
            height: 120px !important;
            border: 1px dashed #A0A0A0 !important;
            background-color: transparent;
            .dropzone-desc{
                top: 20%;
            }
        }

        .product-footer {
            width: 100%;
            background-color: #FAFAFA;
            // height: 70px;
            padding: 20px 20px;
            display: flex;
            align-items: center;
            .close{
                border: 1px solid #E8E9EA;
                box-shadow: 0px 4px 15px rgba(49, 48, 67, 0.05);
            }
            button{
                padding: 10px 15px !important;
            }
        }

        .modal-body {
            height: calc(100vh - 200px);
            overflow-y: auto;
            #sendNewsletterContent {
                background: #F1F1F1 !important;
                border-radius: 20px;
                margin-top: 10px;
            }
        }
    }
}

.accordion-button::after {
    background-size: 12px;
    margin-top: 12px;
}

.accordion-button {
    background-color: #F1F1F1 !important;
    border-radius: 20px !important;
    color: inherit !important;
}

.accordion-button img {
    border-radius: 0px !important;
}

.up-in-toggle.bg-white {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 34px;
    color: $theme-text-color;
    label {
        display: inline-block;
        width: 60px;
        text-align: center;
        font-family: "Quicksand", sans-serif;
        font-size: 0.8rem;
        padding: 6px 16px;
        text-align: center;
        width: 50%;
        float: left;
        transition: all 0.1s ease-in-out;
        &:hover {
        cursor: pointer;
        }
    }
    input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        &:checked + label {
            border: 2px solid $theme-primary;
            font-weight: 500;
            img{
                opacity: 1;
            }
        }
    }
    label{
        font-weight: 500;
        border: 2px solid #E1E1E1;
        border-radius: 10px !important;
        padding-left: 10px;
        padding-right: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        img{
            opacity: 0;
        }
    }
    label:last-of-type {
        border-radius: 20px;
    }
    label:first-of-type {
        border-radius: 20px;
        margin-right: 15px !important;
    }
}


.up-in-toggle.bg-f7f7f8 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 34px;
    color: $theme-text-color;
    background-color: #f7f7f8;
    label {
      display: inline-block;
      width: 60px;
      text-align: center;
      font-family: "Quicksand", sans-serif;
      font-size: 0.8rem;
      padding: 6px 16px;
      text-align: center;
      width: 50%;
      float: left;
      font-weight: 600 !important;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
      }
    }
  
    input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        &:checked + label {
          background: #FFFFFF;
          font-weight: 500;
        }
    }
    label:first-of-type {
        border-radius: 20px;
    }
    label:last-of-type {
        border-radius: 20px;
    }
  }
  

  .product-description-main{
    border: 1px solid #E8E9EA;
    border-radius: 14px;
    min-height: 200px;
  }
  .product-description-header{
    width: 100%;
    background: #F5F9FC;
    border-radius: 14px 13px 0px 0px;
    padding:10px;
    display: flex;
    align-items: center;
  }
  .product-description-header-item{
    background: white;
    border:none;
    border-radius: 10px;
    padding:5px 15px;
    width: auto;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
    -o-transition: 0.2s all ease;
    -ms-transition: 0.2s all ease;
  }
  .product-description-header-item:hover{
    background: #f3f3f3;
  }
  .product-description-body{
    padding:20px 10px;
    /* background-color: red; */
  }
  .product-description-input{
    border:none;
    width: 100%;
    color: $theme-text-lightcolor;
    font-size:16px !important;
  }
  .product-description-input:focus{
    border:none;
    outline:none;
    box-shadow:none;
  }
  .product-description-input::placeholder{
    font-size:16px !important;
    color: #CDCDCD;
  }
  .product-description-dropdown-menu{
    .dropdown-item:focus, .dropdown-item:hover{
        border-radius: 6px !important;
    }
  }
.product-theme {
    min-width: 50px;
    height: 50px;
    border-radius: 50px;
    /* border: 2px solid #EAEAE8; */
    margin-right: 5px;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    margin-bottom: 10px;
    div {
        width: 100%;
        height: 100%;
        border-radius: 50px;
    }
}
.product-theme.active {
    border: 2px solid $theme-primary;
}
#productFiles{
    .form-group{
        width: 250px;
    }
}