#wrapper .dashboard-main {
  position: fixed;
  width: 100%;
  height: calc(100vh - 70px);
  height: calc(var(--app-height) - 60px);
  display: flex;
  align-items: center;
  overflow: auto;
}
@media (max-width:767px) {
  #wrapper .dashboard-main {
    height: calc(var(--app-height) - 105px);
  }
}
#wrapper .left-side-bar {
  transition: all 0.6s ease;
  background-color: #FAFAFA;
  height: 100%;
  padding: 20px 15px;
  padding-bottom: 10px;
  width: 78px;
  /* flex-basis: 25%; */
  /* box-shadow: 8px 0px 40px 0px rgba(0, 0, 0, 0.10); */
}
#wrapper .dashboard-section .dashboard-container{
  /* max-width: 1071px; */
   max-width:1095px;
}
#wrapper .left-side-bar.open{
  width: 260px ;
  min-width: 260px !important;
}
#wrapper .left-side-bar.closed {
  max-width: 78px !important;
  min-width: 78px !important;
  height: 100%;
}
#wrapper .left-side-bar.closed .side-bar-menus .left-menu-item {
  padding: 13px 12px 14px 11px !important;
  display: flex !important;
  justify-content: center !important;
}
#wrapper .left-side-bar.closed .left-menu-item p{
  display: none !important;
}
#wrapper .left-side-bar.closed .side-bar-menus .side-bar-headings {
  display: none !important;
}
#wrapper .left-side-bar.closed .side-bar-menus hr {
  display: block !important;
  border-radius: 7px !important;
  background: #999 !important;
  /* width: 50px !important; */
  height: 2px !important;
  /* margin-left: 17px; */
}
#wrapper .left-side-bar.closed .project-dropdown-main .account-div div:first-child {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
#wrapper .left-side-bar.closed .project-dropdown-main .account-div img:first-child {
  margin-left: 10px;
}
#wrapper .left-side-bar.closed .project-dropdown-main .account-div div,#wrapper .left-side-bar.closed #project-chevron-down{
  display: none !important;
}
#wrapper .left-side-bar .toggle-button {
  cursor: pointer;
}
#wrapper .left-side-bar.closed .toggle-button{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 15px; 
  margin-bottom: 20px; 
}

#wrapper .left-side-bar.open .toggle-button{
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end;
  margin-top: 11px;
}
#wrapper .left-side-bar h4 {
  font-weight: 600 !important;
  padding: 0 10px;
}

#wrapper .left-side-bar .projects-main {
  width: 100%;
  max-height: calc(50vh - 150px);
  max-height: calc(var(--app-height-half) - 150px);
  overflow: auto;
  padding-right: 5px;
}

#wrapper .left-side-bar .projects-main .project-small {
  width: 100%;
  padding: 5px 15px;
  border-radius: 12px;
  cursor: pointer;
}

#wrapper .left-side-bar .projects-main .project-small:hover {
  background-color: #f3f3f3;
}

.project-small-details-main {
  width: calc(100% - 30px);
  overflow-x: hidden;
}

#wrapper .left-side-bar .projects-main .project-small .project-small-user-pp img {
  width: 45px;
}

#wrapper .left-side-bar .projects-main .project-small .project-small-project-title {
  font-size: 18px;
}

#wrapper .left-side-bar .projects-main .project-small .project-small-project-title span {
  width: 230px
}

#wrapper .left-side-bar .projects-main .project-small .project-small-project-link span {
  width: 230px
}

#wrapper .left-side-bar .projects-main .project-small .project-small-project-link {
  font-size: 16px;
}

.project-small-action-button:after {
  display: none;
}

#wrapper .left-side-bar .projects-main .project-small .project-small-manage-button {
  padding: 8px 10px;
  width: 120px;
  background: #F3F3F3;
  border-radius: 10px;
  cursor: pointer;
}

#wrapper .left-side-bar .projects-main .project-small .project-small-manage-button img {
  margin-right: 10px;
}

#wrapper .left-side-bar .projects-main .project-small.active {
  background-color: #EEEDEE;
}

#wrapper .left-side-bar .projects-main::-webkit-scrollbar {
  width: 0.25em;
}

#wrapper .left-side-bar .projects-main::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

#wrapper .left-side-bar .upgrade-div {
  width: 100%;
  background-color: #EEEDEE;
  color: #8C8E9C;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 30vh;
  text-align: center;
}

#wrapper .left-side-bar .account-div {
  width: 100%;
    padding: 12px 15px;
    border-radius: 12px;
    cursor: pointer;
  overflow-wrap: anywhere;
  background: #EEEDEE;
}

#wrapper .left-side-bar .account-div .account-icon {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4397F7;
  color: white;
  font-weight: 500;
}

#wrapper .manage-billing-main .left-side-bar .account-div .account-icon {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4397F7;
  color: white;
  font-weight: 500;
}

#wrapper .dashboard-main .dashboard-section {
  flex-basis: 100%;
  height: 100%;
  overflow-y: auto;
  /* padding: 15px 0; */
  overflow-x: hidden;
}

#wrapper .dashboard-main .dashboard-section .project-details-main {
  width: 100%;
  padding: 15px 0px;
  /* padding: 15px 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
#fade-color{
  width: 100%;
  transition: all 0.6s ease-in-out;
  background: linear-gradient( rgba(0,0,0,0), #ffffff);
	background-color: #ffffff;
  margin-top: -15px;
}
#fade-color:has(.views-card:hover) {
  background-color: rgb(229, 229, 253);
}
#fade-color:has(.clicks-card:hover) {
  background-color: rgb(219, 251, 251);
}
#fade-color:has(.sales-card:hover) {
  background-color: rgb(252, 225, 215);
}
#fade-color:has(.revenue-card:hover) {
  background-color: rgb(252, 241, 217);
}
#fade-color:has(.subscribers-card:hover) {
  background-color:  rgb(253, 216, 236);
}
#fade-color:has(.meetings-card:hover) {
  background-color:  rgb(252, 214, 217);
}
#wrapper .dashboard-main .dashboard-section .project-details-main .cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  /* padding: 20px; */
  padding-top: 15px;
  padding-top: 31px !important;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .card-heading {
  color: #FFFFFF;
  margin-bottom: 0;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .dashboard-card-heading{
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 111%; /* 19.98px */
}
#wrapper .dashboard-main .dashboard-section .project-details-main .dashboard-card-text{
  color: rgba(255, 255, 255, 0.80) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 111%;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .dashboard-card-numbers{
  color: #FFF;
  font-size: 22px;
  font-weight: 700;
  line-height: 111%;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .views-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(102.79deg, #000000 0%, #7F48FB 93.6%); */
  background: linear-gradient(103deg, #7F48FB 0%, #41179F 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .views-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex;
  justify-content: flex-end;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .views-card-image{
  width: 150px;
  margin-top: 24px;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .clicks-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(103deg, #000 0%, #48FBFB 93.6%); */
  background: linear-gradient(103deg, #20F8F8 0%, #23B7B7 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .clicks-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: flex-end !important;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .clicks-card-image{
  margin-top: 60px;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .sales-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(103deg, #000 0%, #FB7348 93.6%); */
  background: linear-gradient(103deg, #FB7348 0%, #D14417 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .sales-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: flex-end !important;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .sales-card-image{
  margin-top: 60px;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .revenue-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(103deg, #000 0%, #FBB348 93.6%); */
  background: linear-gradient(103deg, #FBB348 0%, #D78D1E 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .revenue-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: flex-end !important;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .subscribers-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(103deg, #000 0%, #FB487E 93.6%); */
  background: linear-gradient(103deg, #FB487E 0%, #C11348 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .subscribers-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: flex-end !important;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .subscribers-card-image{
  margin-top: 60px;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .meetings-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 166px;
  height: 165px;
  /* background: linear-gradient(103deg, #000 0%, #FB4848 93.6%); */
  background: linear-gradient(103deg, #FB4848 0%, #D71616 93.6%);
  border: none;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .meetings-card-pic-div{
  width: 100% !important;
  position: absolute !important;
  display: flex !important;
  justify-content: flex-end !important;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .meetings-card-image{
  margin-top: 60px;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .card-text {
  color: #FFFFFF;
  margin-bottom: 8px;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .card {
  border-radius: 25px;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .visitor-card {
  width: 150px;
  padding: 15px;
  height: 160px;
  background: linear-gradient(180deg, #2566F4 0%, #39BEFF 100%);
}
#wrapper .dashboard-main .dashboard-section .project-details-main .visitor-card:hover {
  /* background: linear-gradient(180deg, #2566F4 0%, #39BEFF 100%); */
  box-shadow: 5px 5px rgba(91, 90, 90, 0.1);
}
#wrapper .dashboard-main .dashboard-section .project-details-main .visitors {
  background: rgba(255, 255, 255, 0.15);
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  border-radius: 12px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #wrapper .dashboard-main .dashboard-section .project-details-main .sales-card {
  width: 150px;
  padding: 15px;
  height: 160px;
  background-color: #2C2D30;
}
#wrapper .dashboard-main .dashboard-section .project-details-main .sales-card:hover {
  box-shadow: 5px 5px rgba(91, 90, 90, 0.1);
} */

#wrapper .dashboard-main .dashboard-section .project-details-main .sales {
  background-color: #7F48FB;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  /* border-radius: 12px 12px 25px 25px; */
  border-radius: 20px 20px 25px 25px;
  height: 64px;
  width: inherit;
  position: relative;
  left: -16px;
  bottom: -25px;
  box-shadow: #6839d6 0px -20px 1px -10px,
    #472492 0px -40px 1px -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* #wrapper .dashboard-main .dashboard-section .project-details-main .meetings-card {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 160px;
  background: linear-gradient(102.79deg, #000000 0%, #7F48FB 93.6%);
}
#wrapper .dashboard-main .dashboard-section .project-details-main .meetings-card:hover {
  box-shadow: 5px 5px rgba(91, 90, 90, 0.1);
} */

#wrapper .dashboard-main .dashboard-section .card-details-container {
  width: 50%;
  padding: 15px;
}

#wrapper .dashboard-main .dashboard-section .meetings {
  color: #FFFFFF;
  font-size: 60px;
  margin-top: 20px;
  margin-bottom: 0;
}

#wrapper .dashboard-main .dashboard-section .card-image {
  height: 160px;
  width: 50%;
}

/* #wrapper .dashboard-main .dashboard-section .project-details-main .revenue-card {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 300px;
  height: 160px;
  position: relative;
  background-image: url("../media/dashboard/revenue-card.png");
  background-size: cover;

}
#wrapper .dashboard-main .dashboard-section .project-details-main .revenue-card:hover {
  box-shadow: 5px 5px rgba(91, 90, 90, 0.1);
} */


#wrapper .dashboard-main .dashboard-section .analytics-card {
  width: 347px !important;
  height: 340px !important;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 30px rgba(54, 51, 67, 0.1);
  border-radius: 25px;
  padding: 20px;
}
#wrapper .dashboard-main .dashboard-section .analytics-card .card-name{
  font-weight: 500;
  font-size: 18px;
}
#wrapper .dashboard-main .dashboard-section .social-analytics-card {
  width: 350px;
  height: 350px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 30px rgba(54, 51, 67, 0.1);
  border-radius: 30px;
  background: linear-gradient(135.46deg, #6A0CBF 0%, #F43CA6 34.62%, #F44355 68.21%, #FF9000 99.22%);
}

#wrapper .dashboard-main .dashboard-section .social-platform-details {
  display: flex;
  align-items: center;
  margin: 10px 30px;
}

#wrapper .dashboard-main .dashboard-section .social-analytics {
  background: #fff;
  padding: 20px;
  border-radius: 25px;
  margin: 0px -1px;
}

#wrapper .dashboard-main .dashboard-section .social-platform-details p {
  margin-left: 8px;
  margin-bottom: 0;
  color: #fff;
    font-weight: 500;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail {
  padding: 0px 15px;
  margin-right: 15px;
  padding-right: 0;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail h1 {
  font-weight: 700;
  font-size: 1.25rem;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail h5 {
  font-size: 1rem;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail .analytics-badge {
  border-radius: 5px;
  font-weight: 600;
  padding: 3px 4px;
  font-size: 11px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 15px;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail .analytics-badge.analytics-badge-success {
  background-color: #D5F5E3;
  color: #0CBD5B;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail .analytics-badge.analytics-badge-danger {
  background-color: #FCD9DB;
  color: #EA1320;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-blue {
  border-left: 3px solid #0C8CFB;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-blue h1,
#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-blue h5 {
  color: #0C8CFB;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-purple {
  border-left: 3px solid #7F48FB;
}

#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-purple h1,
#wrapper .dashboard-main .dashboard-section .analytics-detail.analytics-card-purple h5 {
  color: #7F48FB;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

#wrapper .dashboard-main .dashboard-section .card-analytics-container {
  width: 300px;
  height: 200px;
  display: flex;
    align-items: flex-flex-end;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .project-user-pp {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .project-details .project-title {
  /* font-size: calc(1.75rem + .3vw);
  font-weight: 700;
  padding: 0; */
  color: #333;
  font-family: GT Walsheim Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.1%; /* 23.342px */
  letter-spacing: -0.33px;
}

#wrapper .dashboard-main .dashboard-section .project-details-main .project-details .project-link a{
  /* border-radius: 12px;*/
  padding: 5px 9px; 
  /* background: #FFFFFF; */
  color: #8C8E9C;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 14.854px
}

#wrapper .dashboard-main .dashboard-section .project-details-main .project-details .text-muted-light {
  color: #8C8E9C !important;
  font-size: 19px;
  line-height: 106.1%
}

#wrapper .dashboard-main .dashboard-section .project-links-main {
  width: 100%;
  padding: 15px 10px;
  height: 60px;
  padding-bottom: 0;
}

#wrapper .dashboard-main .dashboard-section .project-links-main .project-link {
  padding: 8px 15px;
  background: #F3F3F3;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}

#wrapper .dashboard-main .dashboard-section .project-links-main .project-link span {
  font-weight: 600;
  font-size: 16px;
  margin-left: 7px;
  color: #212529
}

#wrapper .dashboard-main .dashboard-section .project-links-main .project-link img {
  margin-top: -2px;
}

#wrapper .dashboard-main .dashboard-section .project-links-main .project-link:hover {
  background: #ebe9e9;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics {
  width: 100%;
  /* padding: 20px 10px; */
  padding-bottom: 0;
  padding-right: 0;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .page-activity {
  width: 100%;
  background: linear-gradient(154.56deg, #FFBE23 0%, #FF8524 94.32%);
  border-radius: 30px;
  margin-bottom: 10px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .page-activity img {
  margin-top: -15px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .page-activity .see-more-btn {
  border-radius: 10px;
  background: #DD891A;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100% !important;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .page-activity .see-more-btn img {
  margin-top: 2px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .tutorials {
  cursor: pointer;
  height: 25%;
  width: 100%;
  border-radius: 30px;
  background-color: #EEEDEE;
  overflow: hidden;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .sales {
  width: 100%;
  background: linear-gradient(154.56deg, #C31AFF 0%, #680CFF 94.32%);
  border-radius: 30px;
  margin-bottom: 10px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .latest-emails {
  width: 100%;
  background: linear-gradient(105.53deg, #FF463A 3.73%, #FF0FCA 99.03%);
  border-radius: 30px;
  margin-bottom: 10px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .latest-emails.p-0 {
  padding: 0 !important;
  padding-left: 25px !important;
  padding-top: 20px !important;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .latest-emails .pills {
  background-color: #CA0049;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  margin-bottom: 5px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .share-your-page {
  width: 100%;
  background: linear-gradient(270deg, #FFD80C 0%, #F5B40A 100%);
  border-radius: 30px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .share-your-page .copy-link {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #DFAE02;
  font-weight: 600;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .share-your-page .copy-link span {
  flex-basis: 90%;
  overflow: hidden;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .share-your-page .copy-link button {
  flex-basis: 10%;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .community {
  width: 100%;
  background: linear-gradient(154.56deg, #1E011B 0%, #384159 94.32%);
  border-radius: 30px;
  margin-bottom: 10px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .socials {
  width: 100%;
  background: linear-gradient(152.57deg, #1A91FF 0%, #0C77C4 100%);
  border-radius: 30px;
  margin-bottom: 0px;
  color: white;
  padding: 15px 20px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics div .socials .social-divs {
  background: rgba(0, 0, 0, 0.07);
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-first-division {
  width: 30%;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-second-division {
  width: 40%;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-second-division .sales p {
  font-size: 20px;
  width: 60%;
  font-weight: 400;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-second-division .see-more-btn {
  border-radius: 10px;
  background: #5802DA;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100% !important;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-second-division .see-more-btn img {
  margin-top: 2px;
}

#wrapper .dashboard-main .dashboard-section::-webkit-scrollbar {
  width: 0.5em;
}

#wrapper .dashboard-main .dashboard-section .dashboard-analytics .dashboard-analytics-third-division {
  width: 30%;
}

#wrapper .dashboard-main .dashboard-section::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

/*# sourceMappingURL=dashboard.css.map */

.manage-billings {
  padding: 8px 15px;
  background: #F3F3F3;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 7px;
  color: #212529;

}

.dashboard-revenue-card {
  width: 100%;
  /* background: linear-gradient(154.56deg, #1E011B 0%, #384159 94.32%); */
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
  /* margin-bottom: 10px; */
  color: white;
  /* padding: 15px 20px !important;  */
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-right: 0px;
  border: none;
  margin-bottom: -9px !important;
}

.dashboard-revenue-card-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  background: #7F48FB;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  padding-top: 20px !important;
}

.dashboard-revenue-card-body {
  background: linear-gradient(150.59deg, #234660 0%, #020E17 95.95%);
  color: white;
  font-size: 21px;
  font-weight: 700;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.dashboard-revenue-card-footer {
  margin-bottom: 0%;
  padding: 3px 1px;
}

.dashboard-revenue-card-footer .row {
  font-size: 14px;
  font-weight: lighter;
}

.progress {
  height: 7px;
  /* margin-bottom: 20px; */
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.11) !important;
  border-radius: 4px;
  margin-bottom: 8px;
}

.progress-bar {
  background-color: #7F48FB;
}
.share-dropdown{
  position: absolute;
  inset:0px 0px auto auto !important;
  margin:0px;
  transform: translate(-88px, 50px) !important;
  width: 229px !important;
}
.share-dropdown-text{
  font-weight: 500;
  font-size: 12px;
}
.copy-button{
  background-color: #F3F3F3;
  color: #333333;
  padding: 8px 10px;
  font-weight: 500;
  border-radius: 8px;
  /* margin-right: 7px; */
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  font-size: 12px;
  height: 30px;
  width: 50px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
.share-dropdown-item {
    display: block;
    width: 100%;
    height: 30px;
    padding-left:12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    border-radius: 9px;
    /* padding-right: 0px; */

}
.share-dropdown-item:focus, .share-dropdown-item:hover{
  background-color: #e9ecef !important;
}
.share-dropdown-item:hover .dropfill path {
  fill: #333333 !important;
}

#wrapper .left-side-bar .left-menu-item {
  width: 100% !important;
  border-radius: 9px!important;
  padding: 8px!important;
  display: flex!important;
  align-items: center!important;
  /* margin-bottom: 1px!important; */
  text-decoration: none!important;
  /* height: 30px; */
}
#wrapper .left-side-bar .left-menu-item svg{
  min-width: 16px;
}
#wrapper .left-side-bar .left-menu-item p {
  color: #333333 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding-top: 2px !important;
  display: block;
  /* transition: all 0.6s ease; */
  text-wrap: nowrap;
}
#wrapper .left-side-bar .left-menu-item:hover {
  text-decoration: none !important;
  background-color: #EEEDEE !important;
}
#wrapper .left-side-bar.closed .left-menu-item:hover {
  border-radius: 16px !important;
}
#wrapper .left-side-bar.open .left-menu-item.active {
  background-color: #EEEDEE !important;
  /* width: 229px ; */
}
#wrapper .left-side-bar.closed .left-menu-item.active{
  background-color: #EEEDEE !important;
  width: 45px ;
  border-radius: 16px !important;
}
#wrapper .left-side-bar .account-div {
  width: 100%;
    padding: 12px 15px;
    border-radius: 16px;
    cursor: pointer;
  overflow-wrap: anywhere;
  background: #EEEDEE;
  height: 61px;
}
#wrapper .left-side-bar.open .account-div{
  max-width: 229px !important;
  min-width: 229px !important;
}
#wrapper .left-side-bar .account-div .account-div-title-main{
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;

}
.dashboard-analytics .date-filter-button{
  background: rgb(243, 243, 243);
  border-radius: 8px;
  display: inline-flex;
  padding: 4px 10px;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}
.dashboard-analytics .date-filter-button.active{
  background-color: #1188FF;
  color: white;
}
#wrapper .dashboard-main .dashboard-section .analytics-detail h1 {
font-size: 18px;
margin-top: 2px;
margin-bottom: 2px;
}
#wrapper .dashboard-main .dashboard-section .analytics-detail h5 {
font-size: 14px;
}
.date-btns-selector{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.project-dropdown.show .arrowact ,
.header-dropdown.show .arrowact
{
  transform: rotate(180deg) !important;
}

/* profile complete card page css start here */

.main-card{
  width: 508px;
  height: 500px;
  border-radius: 20px;
  background: #181818;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding:20px ;
}
.card-banner{
  position: absolute;
  bottom: -5px;
  z-index: 0;
}
.card-top-u{
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-head{
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-top: 13px;
}
.readylinkbox{
  border-radius: 12px;
  background: #383838;
  width: 360px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px; 
  margin-top: 26px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.blue-btn-u{
  display: inline-flex;
  height: 25px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  background: #18F;
  color: #FFF;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-bottom-u{
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.red-btn-u{
  display: flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #FF1451;
  box-shadow: 0px 4px 15px 0px rgba(49, 48, 67, 0.10);
  color: #FFF;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.18px;
}
.red-btn-u>span{
  display: flex;
  height: 13px;
  padding: 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #CF2551;
}

@media (max-width:510px) {
  .main-card{
      width: 100%;
  }
}
@media (max-width:430px) {
  .readylinkbox {
      width: 100%;
  }
}
.demo-banner{
  width: 100%;
  height: 100dvh;
  background-image: url("../img/demo-image.png");
  background-position: left;
  background-size:cover;
  background-repeat: no-repeat;
  position: absolute;
}
.endcard-bg{
  width: 100%;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(2.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 15;
  padding: 20px;
}
/* profile complete card page css end here */
.checklist-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 21px;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 106.1%; /* 19.098px */
  letter-spacing: -0.27px;
}
.checklist-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.checklist-card {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 15px;
  padding: 20px;
  flex-direction: column;
  border-radius: 16px;
  background: #FAFAFA;
  max-width: 345px;
  width: 100%;
}
.checklist-card .title {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 111%; /* 15.54px */
}
.checklist-card .description {
  color: #5B5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 130.1%; /* 15.612px */
  letter-spacing: -0.18px;
}
.checklist-footer .number {
  color: #5B5B5B;
  font-size: 12px;
  font-weight: 500;
  line-height: 130.1%; /* 15.612px */
  letter-spacing: -0.18px;
}
.checklist-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1458px) {
  .checklist-main{
    flex-wrap: wrap;
  }
}
.sidebar-sub-menu-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-sub-menu-main .first-half{
  display: flex;
  gap: 10px;
  align-items: center;
}
.left-side-bar.closed .sidebar-sub-menu-main .dropdown-arrow {
  display: none;
}