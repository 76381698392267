@import 'colors';
#wrapper{
    margin: 0 auto;

    header{
        width: 100%;
        border-bottom:1px solid $border-color;
        padding: 10px 15px;
        height: 70px;
        .header-dropdown-main{
            display: flex;
            align-items: center;
            justify-content: start;
            .header-dropdown{
                padding:8px 12px;
                box-shadow: 0px 8px 13px rgba(86, 85, 106, 0.07);
                border-radius: 10px;
                transition: all 0.2s ease;
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 8px 13px rgba(86, 85, 106, 0.15);
                }
                &:after {
                    display: none;
                }
                #header-chevron-down{
                    transition: all 0.2s ease;
                }
                
            }
            .header-dropdown.active{
                #header-chevron-down{
                    transform: rotate(180deg);
                }
            }
            
            
        }
        
    }

    .header-links-main{
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;
        padding: 15px 10px;
    }
    .header-links{
        display: flex;
        align-items: center;
        justify-content: start;
        .header-link{
            background-color: #F3F3F3;
            color: #333333;
            padding:8px 15px;
            font-weight: 600;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            &:hover{
                color: white;
                background-color: #333333;
                svg path{
                    stroke: white !important;
                }
            }
        }
        .header-link.active{
            color: white;
            background-color: #333333;
            svg path{
                stroke: white !important;
            }
        }
    }
    .dropdown-menu{
        border-radius: 10px;
        box-shadow: 0px 4px 22px rgba(87, 88, 111, 0.13);
        border:none;
        margin-top:10px !important;
        padding: 0.5rem;

        li{
            a{
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 500;
                padding: .25rem .5rem .25rem 0.5rem;
                &:hover{
                    background-color: $theme-blue;
                    color: #FFFFFF;
                    svg path{
                        fill: #FFFFFF;
                    }
                }
                
            }
            .theme-active{
                background-color: $theme-primary-bg !important;
                color: $theme-primary-variant !important;
                svg path{
                    fill: $theme-primary-variant !important;
                }
            }
        }
        .dropdown-divider{
            border-top: 2px solid #E9E9E9 !important;
        }
    }


}


.semi-bold{
    font-weight: 600;
}
.text-muted{
    color: #999999;
}
.text-muted-light{
    color: #9F9F9F !important;
    text-decoration: none;
    &:hover{
        color: #8C8E9C;
        text-decoration: none;
    }
}
.text-gray{
    color: $theme-text-color !important;
}
.text-lightgray{
    color: $theme-text-lightcolor !important;
}


.zaap-badge{
    padding: 1px 10px;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 600;
}
.badge-free{
    color: #118EFB;
    background-color: #E6F3FF;
}
.copy-clipboard{
    cursor: pointer;
}
.btn{
    border-radius: 20px;
}
.btn-radius-10{
    border-radius: 10px !important;
}
.btn-radius-12{
    border-radius: 12px !important;
}
.py-01{
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}
.btn-primary{
    background-color: $theme-primary;
    color: white;
    border-color: $theme-primary;
    &:hover{
        background-color: $theme-primary-darker;
        border-color: $theme-primary-darker;
    }
    &:active{
        background-color: $theme-primary-darker;
        border-color: $theme-primary-darker;
        box-shadow:0 0 0 0.25rem $theme-primary-lighter !important;
    }
    &:focus{
        background-color: $theme-primary-darker;
        border-color: $theme-primary-darker;
        box-shadow:0 0 0 0.25rem $theme-primary-lighter !important;
    }
}
.text-primary{
    color: $theme-primary !important;
}
.btn-block{
    width: 100%;
    font-size: 20px;
}
.upgrade-main-btn{
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #FFC122 0%, #FF7F23 100%);
    border-radius: 13px;
    color: #FFFFFF;
    padding: 10px 18px;
    font-weight: 600;
    letter-spacing: 1px;
    &:hover{
        color: #FFFFFF;
    }
}
h1,h3,h4,h5{
    font-weight: 600;
}
.py-25{
    padding-top: 0.75em !important;
    padding-bottom: 0.75em !important;
}
input:-webkit-autofill {
    /* -webkit-text-fill-color: red !important; */
    -webkit-box-shadow: 0 0 0 30px #F6F6F6 inset !important;
    color: rgba(14, 18, 59, 0.5) !important;
}
body.modal-open #wrapper div, body.modal-open #wrapper button{
  -webkit-filter: blur(1.5px);
  -moz-filter: blur(1.5px);
  -o-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}
.modal-content{
    border-radius: 2.2rem !important;
    overflow: hidden;  
}
.modal-border-straight{
    border-radius: 0 !important;
}
.modal-content.overflow-none{
    overflow: visible;
}
.modal-content.modal-semi-curve{
    border-radius: 1.2rem !important;
}
.modal-header{
    padding: 15px 25px !important;
    /* padding-bottom: 0.2rem; */
    border-bottom: 1px solid #E8E9EA;
    .close{
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        float: right;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        background-color: transparent;
        border: 0;
    }
}
.dropzone-wrapper {
    border: 1px dashed #53A4F6;
    position: relative;
    height: 200px;
    border-radius: 30px;
    background: #F5F9FC;
  }
  
  .dropzone-desc {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    top: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .dropzone-desc p{
    font-size: 18px;
  }
  .dropzone-desc small{
    color: #6B6B6B;
  }
  .dropzone,
  .dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .dropzone-wrapper:hover,
  .dropzone-wrapper.dragover {
    background: #ecf0f5;
  }

  .full-page-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 111111;
    display: flex;
    /* padding-top: 20%; */
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }
  
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-ripple div {
    position: absolute;
    border: 4px solid $theme-primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
  
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
.dev_upgrade_new_main {
    background: #F7F7F8;
    border-radius: 14px;
    color: #3D404C;
    width: 100%;
}
.dv_upgrade_new {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dv_upgrade_new img {
    width: 1.8rem;
    height: auto;
    text-align: center;
}
.dev_upgrade_new_main h5 {
    font-size: 18px;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.cursor-pointer{
    cursor: pointer;
}
.btn-grey{
    background-color: #F1F1F1 !important;
}
.btn-semi-rounded{
    border-radius: 15px !important;
}
.btn-grey.btn-rounded {
    // padding: 15px 8px;
    font-size: 16px;
    border: 1px solid #D0D5DD !important;
    background-color: white !important;
}
.rotate180{
    transform: rotate(180deg);
}
.font-weight-500{
    font-weight: 500 !important;
}
.font-weight-600{
    font-weight: 600 !important;
}
.btn{
    &:focus,&:active,&:visited{
        box-shadow: none !important;
    }
}
.bg-primary-dark{
    background-color: $theme-primary-dark;
}
.btn-light-shadow{
    box-shadow: 0px 4px 15px rgba(35, 35, 68, 0.1);
}
.bg-f1f1f1{
    background: #F1F1F1 !important;
}
.resize-none{
    resize: none !important;
}

.zaap-form-control{
    border-radius: 12px !important;
    border: 1px solid #E1E1E1 !important;
    color: black !important;
    font-weight: 500;
    padding: 10px 15px!important;
    &::placeholder{
        color: #CDCDCD !important;
        font-weight: 400;
    }
    &:active,&:focus{
        box-shadow: 0 0 0 0.05rem rgb(0 0 0 / 5%);
    }
}
.borderless{
    border: none !important;
    box-shadow: none !important;
}
.btn-light{
    background-color: #F3F3F3;
}
.sortable-col{
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.sortable-col-desc{
    background-color: #F3F3F3 !important;
    img{
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
    }
}
   
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 80px;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

.zaap-border-none{
    border: none !important;
}
.zaap-border-none:hover{
    border: none !important;
}
.zaap-border-straight{
    border-radius: 0px !important;   
}
.zaap-border-rounded{
    border-radius: 12px !important;   
}
.zaap-border-rounded.border-radius-10{
    border-radius: 10px !important;
}
.zaap-border-rounded-left{
    border-top-left-radius: 12px !important;   
    border-bottom-left-radius: 12px !important;   
}
.zaap-border-rounded-right{
    border-top-right-radius: 12px !important;   
    border-bottom-right-radius: 12px !important;   
}
.accordion::-webkit-scrollbar {
    width: 0em;
}
.accordion-button::after{
    background-image: url('../img/modals/chevron-down.svg') !important;
    width: 15px;
    height: 15px;
    background-size: 15px;
    margin-top: 12px;
}
.accordion-button:not(.collapsed)::after {
    transform: rotate(180deg) translateY(8px) !important;
}
.accordion-button:not(.collapsed){
    background-color: #F1F1F1 !important;
    border-radius: 20px !important;
    color: inherit !important;
}
.accordion-button img{
    box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
    border-radius: 10px !important;
}
.bg-gray{
    background-color: #E8E9EA !important;
}
.bg-f3f3f3{
    background-color: #F3F3F3 !important;
}
.text-red{
    color: #FF044F !important;
}
.decoration-none{
    text-decoration: none !important;
}
.form-control[readonly]{
    background-color: #fbfbfb !important;
}
.form-group-disabled{
    label{
        color: #CDCDCD !important;
        small{
            color: #CDCDCD !important;
        }
    }
}

.email-blocks-main-template-field {
    width: 100%;
    /* height: 330px; */
    /* background: red; */
    padding: 10px;
}

.email-blocks-dropdown-template-field {
    width: 220px;
    height: 100%;
    background-color: white;
    border-radius: 30px;
    padding: 20px 0px;
    box-shadow: 0px 0px 50px rgba(6, 20, 40, 0.05);
}

.email-blocks-block-template-field {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.email-blocks-block-template-field:hover {
    background-color: #F2F3F5;
}

.email-blocks-block-svg-template-field {
    width: 40px;
}

#email-body-h1-tag {
    font-size: 32px !important;
    border: none;
    outline: none;
    color: #000;
    width: 95%;
    font-weight: bold;
    background-color: transparent;
}

#email-body-h1-tag::placeholder {
    color: rgba(14, 18, 59, 0.25);
}

#email-body-h2-tag {
    font-size: 22px !important;
    border: none;
    outline: none;
    color: #000;
    width: 95%;
    font-weight: bold;
    background-color: transparent;
}

#email-body-h2-tag::placeholder {
    color: rgba(14, 18, 59, 0.25);
}

#email-body-divider-tag {
    height: 1px;
    width: 95%;
    background: rgba(3, 3, 22, 0.3);
}

#email-body-image-tag {
    background-color: white;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 20px;
    color: rgba(14, 18, 59, 0.5);
    box-shadow: 0px 0px 10px rgba(6, 20, 40, 0.10);
}

#email-body-p-tag {
    font-size: 14px !important;
    border: none;
    outline: none;
    color: #000;
    width: 95%;
    font-weight: bold;
    background-color: transparent;
}

#email-body-p-tag::placeholder {
    color: rgba(14, 18, 59, 0.25);
}

#email-body-button-tag {
    font-size: 14px !important;
    border: none;
    outline: none;
    color: #FFF;
    padding: 10px 10px;
    min-width: 194px;
    border-radius: 50px;
    background-color: #FF1451;
    /* margin:0 auto; */
    text-align: center;
    font-weight: bold;
}

#email-body-button-link-tag {
    font-size: 14px !important;
    border: none;
    outline: none;
    color: #000;
    width: 95%;
    font-weight: bold;
    background-color: transparent;
}

#email-body-button-link-tag::placeholder {
    color: rgba(14, 18, 59, 0.25);
}
.product-description-dropdown-menu{
    border-radius: 10px;
    box-shadow: 0px 4px 22px rgb(87 88 111 / 13%);
    border: none;
    margin-top: -30px !important;
    padding: 0.5rem;
}
.opacity-0{
    opacity: 0 !important;
}
