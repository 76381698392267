@import "colors";

#wrapper {

    .analytics-main {
        width: 100%;
        height: calc(100vh - 70px);
        display: flex;
        align-items: center;

        .left-side-bar {
            background-color: #FAFAFA;
            height: 100%;
            padding: 20px 15px;
            padding-bottom: 10px;
            flex-basis: 25%;

            .left-menu-item {
                width: 100%;
                border-radius: 15px;
                padding: 15px 20px;
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                text-decoration: none;

                p {
                    color: #333333;
                    font-weight: bold;
                    font-size: 18px;
                    padding-top: 2px;
                }

                &:hover {
                    text-decoration: none;
                    background-color: #EEEDEE;
                }
            }

            .left-menu-item.active {
                background-color: #EEEDEE;
            }
        }

        .analytics-section {
            flex-basis: 75%;
            height: 100%;
            overflow-y: auto;
            // background-color: #118EFB;
            padding: 30px;
            padding-left: 50px;

            .section-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .section-main {
                width: 100%;

                .analytics-card{
                    padding:0px 15px;
                    margin-right: 15px;
                    padding-right: 0;

                    h1{
                        font-weight: 600;
                        font-size: 2.2rem;
                    }
                    h5{
                        font-size: 1rem;
                    }
                    .analytics-badge{
                        border-radius: 5px;
                        font-weight: 600;
                        padding:0px 6px;
                        font-size: 13px;
                    }
                    .analytics-badge.analytics-badge-success{
                        background-color: #D5F5E3;
                        color: #0CBD5B;
                    }
                    .analytics-badge.analytics-badge-danger{
                        background-color: #FCD9DB;
                        color: #EA1320;
                    }
                }
                .analytics-card.analytics-card-blue{
                    border-left: 3px solid #0C8CFB;
                    h1,h5{
                        color: #0C8CFB;
                    }
                }
                .analytics-card.analytics-card-purple{
                    border-left: 3px solid #7F48FB;
                    h1,h5{
                        color: #7F48FB;
                    }
                }
                .analytics-card.analytics-card-pink{
                    border-left: 3px solid #FB48C9;
                    h1,h5{
                        color: #FB48C9;
                    }
                }
                .analytics-card.analytics-card-orange{
                    border-left: 3px solid #FB9E48;
                    h1,h5{
                        color: #FB9E48;
                    }
                }

                .date-filter-dropdown,.filter-dropdown {
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 10px;

                    &::after {
                        display: none !important;
                    }

                    &:hover {
                        background-color: #F3F3F3 !important;
                    }
                }

                .date-filter-dropdown{
                    background-color: #F3F3F3 !important;
                }
                .date-filter-dropdown.show,.filter-dropdown.show {
                    background-color: #F3F3F3 !important;
                }

                .date-filter-dropdown-menu,.filter-dropdown-menu {
                    li {
                        a {
                            &:hover {
                                background-color: #FFF !important;
                                // color: inherit !important;
                            }

                            span {
                                img {
                                    opacity: 0;
                                }
                            }

                        }

                        a.active {
                            background-color: #FFF !important;
                            // color: #4397F7 !important;

                            span {
                                font-size: 16px !important;

                                img {
                                    opacity: 1;
                                    margin-top: -4px !important;
                                }
                            }
                        }
                    }
                }
                [type="checkbox"]:checked,
                [type="checkbox"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                [type="checkbox"]:checked + label,
                [type="checkbox"]:not(:checked) + label
                {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    color: #666;
                }
                [type="checkbox"]:checked + label:before,
                [type="checkbox"]:not(:checked) + label:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 3px solid #4397F7;
                    background: #fff;
                    border-radius: 6px;
                }
                [type="checkbox"]:checked + label:after,
                [type="checkbox"]:not(:checked) + label:after {
                    content: "";
                    width: 18px;
                    height: 18px;
                    background: #4397F7;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    transition: all 0.2s ease;
                    border-radius: 5px;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
                [type="checkbox"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                [type="checkbox"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }

                
            }
        }

        .analytics-section::-webkit-scrollbar {
            width: 0.5em;
        }

        .analytics-section::-webkit-scrollbar-thumb {
            background-color: rgba(128, 128, 128, 0.5);
            border-radius: 5px;
        }

    }
}
.mbsc-calendar-cell{
    div:not(.mbsc-calendar-cell-inner){
        display: none !important;
    }
    .mbsc-calendar-cell-inner{
        div:not(.mbsc-calendar-month-name){
            display: inline-block !important;
        }
    }
}
.mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: #434343 !important;
    font-size: 14px !important;
}
.mbsc-icon{
    width: 1em !important;
}
.mbsc-ios.mbsc-calendar-day-text{
    background: #EFEFEF !important;
    border-radius: 7px !important;
}
.mbsc-calendar-title{
    font-weight: 500 !important;
    font-size: 14px !important;
}
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    background: #4397F7 !important;
    border-color: #4397F7 !important;
    color: #fff;
}
.mbsc-ios.mbsc-datepicker-inline{
    border: none !important;
}
.text-analytics-blue{
    color: #0C8CFB !important;   
}
.text-analytics-purple{
    color: #7F48FB !important;   
}
.text-analytics-pink{
    color: #FB48C9 !important;   
}
.text-analytics-orange{
    color: #FB9E48 !important;   
}
.text-analytics-lightblue{
    color: #49DBFB !important;   
}
.analytics-progress-bar{
    width: 100%;
    border-radius: 10px;
    height: 10px;
    div{
        height: 10px;
        border-radius: 10px;
    }
}
.analytics-progress-bar-large{
    height: 35px !important;
    div{
        height: 35px !important;
    }
}
.analytics-progress:nth-child(1) .analytics-progress-bar,.analytics-stats:nth-child(1) .analytics-progress-bar{
    background-color: #D0E9FF;
    div{
        background-color: #0C8CFB;
    }
}
.analytics-progress:nth-child(2) .analytics-progress-bar,.analytics-stats:nth-child(2) .analytics-progress-bar{
    background-color: #E8DEFF;
    div{
        background-color: #7F48FB;
    }
}
.analytics-progress:nth-child(3) .analytics-progress-bar,.analytics-stats:nth-child(3) .analytics-progress-bar{
    background-color: #FFE2F7;
    div{
        background-color: #FB48C9;
    }
}
.analytics-progress:nth-child(4) .analytics-progress-bar,.analytics-stats:nth-child(4) .analytics-progress-bar{
    background-color: #FFEBD9;
    div{
        background-color: #FB9E48;
    }
}
.analytics-progress:nth-child(5) .analytics-progress-bar,.analytics-stats:nth-child(5) .analytics-progress-bar{
    background-color: #E4FAFF;
    div{
        background-color: #49DBFB;
    }
}
.analytics-stats{
    .bg-f3f3f3{
        min-width: 120px;
    }
}
.analytics-table{
    thead{
        th:nth-child(1){
            width: 25%;
        }
        th:nth-child(3){
            width: 45%;
        }
        th:nth-child(2),th:nth-child(4){
            width: 15%;
        }
    }
    tbody{
        td:nth-child(1){
            width: 25%;
        }
        td:nth-child(3){
            width: 45%;
        }
        td:nth-child(2),td:nth-child(4){
            width: 15%;
        }
    }
    .analytics-table-pill{
        background-color: #F3F3F3;
        border-radius: 12px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        color: #434343 !important;
        font-weight: 500;
        height: 40px;
        img{
            margin-right: 8px;
        }
    }
}