$xxl-viewport: 1600px;
$xl-viewport: 1400px;
$lg-viewport: 1200px;
$md-viewport: 992px;
$sm-viewport: 767px;
$xs-viewport: 550px;



@media (min-width: $md-viewport){
    .modal-dialog.modal-xlarge {
        max-width: 850px;
        margin: 1.75rem auto;
    }
    
}
@media (min-width: $sm-viewport){
    .modal-dialog.modal-large {
        max-width: 650px;
        margin: 1.75rem auto;
    }
    .modal-dialog.modal-mlarge {
        max-width: 750px;
        margin: 1.75rem auto;
    }
    
    .d-md-table-header-group{
        display: table-header-group !important;
    }
    .d-md-table-cell{
        display: table-cell !important;
    }
}



@media (min-width: $xxl-viewport){
    #wrapper{
        width: 1520px;
    }
}
@media (min-width: $xl-viewport){
    #wrapper{
        width: 1320px;
    }
}
@media (max-width: $lg-viewport){
    #wrapper{
        .project-links-main{
            flex-wrap: wrap;
            height: auto !important;
            .project-link{
                margin-bottom: 5px;
            }
        }
        .dashboard-analytics{
            flex-wrap: wrap;
            .dashboard-analytics-first-division{
                width: 40% !important;
            }
            .dashboard-analytics-second-division{
                width: 60% !important;
            }
            .dashboard-analytics-third-division{
                width: 100% !important;
                margin-top: 10px;
                flex-direction: row !important;
                align-items: flex-start !important;
                padding-left: 0 !important;
                .community{
                    margin-right: 10px;
                }
            }
        }
    }
}

@media (max-width: $md-viewport){
    #wrapper{
        
        .header-dropdown-main{
            .header-links{
                display: none !important;
            }
        }
        
        .page-editor-main{
            height: auto !important;
        }
        .page-editor-section{
            padding-top: 10px !important;
            .header-links{
                display: flex !important;
                overflow-y: hidden;
                overflow-x: auto;
            }
        }
        .page-editor-main{
            flex-wrap: wrap !important;
            height: auto !important;
            
            .page-editor-section{
                flex-basis: 100% !important;
            }
            .right-side-bar{
                flex-basis: 100% !important;
                padding-bottom: 40px !important;
                .page-preview-mobile{
                    height: 500px !important;
                }
                .page-preview-link{
                    width: 90% !important;
                }
            }
        }
        .community-main{
            padding:10px !important;
            padding-top: 10px !important;
            flex-direction: column;
            height: auto !important;
            .left-side-bar{
                display: flex !important;
                flex-direction: row !important;
                width: 100%;
                background-color: white !important;
                border-radius: 18px !important;
                box-shadow: 0px 0px 20px rgb(55 55 81 / 8%);
                max-height: 60px !important;
                padding: 18px 10px !important;
                .left-menu-item{
                    padding: 8px !important;
                    justify-content: center !important;
                    margin-bottom: 0 !important;
                    img{
                        display: none;
                    }
                }
            }
            .community-section{
                flex-basis: 100% !important;
                padding:30px 20px !important;
                padding-top: 10px !important;
                width: 100%;
                margin-top: 20px !important;
            }
            .header-links{
                display: flex !important;
                overflow-y: hidden;
                overflow-x: auto;
                width: 100%;
                padding-left: 15px;
            }
        }
        .monetization-main{
            padding:10px !important;
            padding-top: 10px !important;
            flex-direction: column;
            // height: auto !important;
            .left-side-bar{
                display: flex !important;
                flex-direction: row !important;
                width: 100%;
                background-color: white !important;
                border-radius: 18px !important;
                box-shadow: 0px 0px 20px rgb(55 55 81 / 8%);
                max-height: 60px !important;
                padding: 18px 10px !important;
                .left-menu-item{
                    padding: 8px !important;
                    justify-content: center !important;
                    margin-bottom: 0 !important;
                    img{
                        display: none;
                    }
                }
            }
            .monetization-section{
                flex-basis: 100% !important;
                padding:30px 20px !important;
                padding-top: 10px !important;
                width: 100%;
                margin-top: 20px !important;
            }
            .header-links{
                display: flex !important;
                overflow-y: hidden;
                overflow-x: auto;
                width: 100%;
                padding-left: 15px;
            }
        }
        .analytics-main{
            padding:10px !important;
            padding-top: 10px !important;
            flex-direction: column;
            height: auto !important;
            .left-side-bar{
                display: flex !important;
                flex-direction: row !important;
                width: 100%;
                background-color: white !important;
                border-radius: 18px !important;
                box-shadow: 0px 0px 20px rgb(55 55 81 / 8%);
                max-height: 60px !important;
                padding: 18px 10px !important;
                .left-menu-item{
                    padding: 8px !important;
                    justify-content: center !important;
                    margin-bottom: 0 !important;
                    img{
                        display: none;
                    }
                }
            }
            .analytics-section{
                flex-basis: 100% !important;
                padding:30px 20px !important;
                padding-top: 10px !important;
                width: 100%;
                margin-top: 20px !important;
            }
            .header-links{
                display: flex !important;
                overflow-y: hidden;
                overflow-x: auto;
                width: 100%;
                padding-left: 15px;
            }
        }
    }

    #customizePageModal{
        .modal-dialog.modal-xlarge {
            max-width: 90vw;
            margin: 1.75rem auto;
        }
    }
    
}

@media (max-width: 860px){
    #wrapper{
        .dashboard-analytics{
            flex-wrap: wrap;
            .dashboard-analytics-first-division{
                width: 100% !important;
            }
            .dashboard-analytics-second-division{
                width: 100% !important;
                padding-left: 0 !important;
                margin-top: 10px;
            }
            .dashboard-analytics-third-division{
                width: 100% !important;
                margin-top: 10px;
                flex-direction: column !important;
                
            }
        }
    }
}

@media (max-width: $sm-viewport){
    #wrapper{
        .dashboard-main{
            .left-side-bar{
                flex-basis: 100%;
                h4{
                    padding-left: 0 !important;
                }
                .projects-main{
                    // min-height: calc(100vh - 250px);
                    .project-small{
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        flex-wrap: wrap;
                        .project-small-details-main{
                            width: calc(100vw - 170px);
                            overflow-x: hidden;
                        }
                    }
                    .project-small.active{
                        background-color: transparent !important;
                    }
                }
                .upgrade-div{
                    display: none !important;
                }
                .account-div{
                    display: none !important;
                }
            }
            .dashboard-section{
                display: none;
            }
        }
        .modal-content{
            padding: 1rem 0.2rem;
        }
    }
    #newNewsletterModal {
        .modal-content {
            width: 100vw !important;
        }
    }
    #newProductModal {
        .modal-content {
            width: 100vw !important;
        }
    }

    .analytics-card{
        flex-basis: 50%;
        margin-right: 0 !important;
        margin-top: 5px !important;
    }
    .analytics-header{
        flex-direction: column-reverse;
        align-items: flex-start !important;
        .analytics-filter-main{
            margin-bottom: 20px;
        }
    }

}

@media (max-width: $xs-viewport){
    #wrapper{
        header{
            .upgrade-main-btn{
                margin-right: 0 !important;
            }
        }
        .page-editor-section{
            padding-left: 0 !important;
            padding-right: 0 !important;
            background-color: transparent !important;
            .header-links{
                padding-left: 10px !important;
            }
            .editor-header{
                padding-left: 10px !important;
                padding-right: 10px !important;
                button{
                    padding:15px 5px !important;
                }
                .btn-add-block{
                    margin-right: 10px !important;
                }
                .btn-customize-page{
                    font-size: 14px !important;
                }
            }
            .editor-links-main{
                padding-right: 10px !important;
                padding-left: 10px !important;
                .editor-link{
                    box-shadow: 0px 4px 24px rgba(73, 70, 96, 0.1);
                    flex-direction: column;
                    align-items: flex-start !important;
                    padding:0 !important;
                    .editor-link-first-half{
                        padding: 15px;
                        width: 100%;
                    }
                    .editor-link-second-half{
                        padding: 15px;
                        border-top: 1px solid #E7E6EA;
                        width: 100%;
                        justify-content: space-between !important;
                    }
                }
                .editor-link-collapsible{
                    padding-left: 30px !important;
                }
            }
        }
        
    }
    .new-block-div {
        flex-basis: 50% !important;
    }
    .custom-domain-input-group{
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 15px 10px !important;
        p{
            margin-bottom: 10px !important;
        }
    }
}
