@import 'colors';
#wrapper{
    margin: 0 auto;

    .page-editor-main{
        width: 100%;
        height: calc(100vh - 70px);
        display: flex;
        // align-items: center;
        .page-editor-section{
            flex-basis: 70%;
            height: 100%;
            overflow-y: auto;
            padding:30px 5%;
            background-color: #FAFAFA;
            .editor-header{
                width: 100%;
                button{
                    width: 50%;
                    padding:10px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                }
                .btn-add-block{
                    background: linear-gradient(90deg, #1677FB 0%, #8202E6 81.93%);
                    box-shadow: 0px 4px 16px rgba(123, 118, 141, 0.1);
                    color: white;
                }
                .btn-customize-page{
                    background: white;
                    box-shadow: 0px 4px 16px rgba(123, 118, 141, 0.1);
                }
            }
            .editor-links-main{
                width: 100%;
                padding: 20px 0;
                margin-top: 10px;
                padding-bottom: 0;
                .editor-link{
                    width: 100%;
                    background: white;
                    color: #333333;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 20px;
                    margin-bottom: 15px;
                    .link-action-button{
                        background-color: #F7F7F8;
                        width: 35px;
                        height: 35px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border:none;
                        &:hover{
                            background-color: #ebebeb;
                        }
                        &:active,&:focus,&:focus-visible{
                            border:none;
                            box-shadow: none;
                        }
                        img{
                            transition: 0.2s all ease;
                            -webkit-transition: 0.2s all ease;
                            -moz-transition: 0.2s all ease;
                            -o-transition: 0.2s all ease;
                            -ms-transition: 0.2s all ease;
                        }
                    }
                }
                .editor-link-collapsible{
                    width: 100%;
                    padding-left: 45px;
                    padding-bottom: 10px;
                    display: none;
                }
            }
        }
        .page-editor-section::-webkit-scrollbar {
            width: 0.2em;
        }   
        .page-editor-section::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 5px;
        }
        .right-side-bar{
            height: 100%;
            padding:20px 15px;
            padding-bottom: 10px;
            flex-basis: 30%;
            .page-preview-link{
                border-radius: 50px;
                box-shadow: 0px 0px 20px rgba(88, 86, 107, 0.11);
                width: 80%;
                padding: 15px 15px;
                font-size: 14px;
            }
            .page-preview-mobile{
                width: 80%;
                height: 75%;
                box-shadow: 0px 11px 53px rgba(6, 4, 30, 0.1);
                border-radius: 40px;
                overflow: hidden;
                .page-preview-loader{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    // background-color: red;
                    margin-top: -160%;
                    div.spinner {
                        position: relative;
                        width: 54px;
                        height: 54px;
                        display: inline-block;
                        padding: 10px;
                        border-radius: 10px;
                    }
                      
                      div.spinner div {
                        width: 6%;
                        height: 16%;
                        background: #333333;
                        position: absolute;
                        left: 49%;
                        top: 43%;
                        opacity: 0;
                        -webkit-border-radius: 50px;
                        -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
                        -webkit-animation: fade 1s linear infinite;
                      }
                      
                      @-webkit-keyframes fade {
                        from {opacity: 1;}
                        to {opacity: 0.25;}
                      }
                      
                      div.spinner div.bar1 {
                        -webkit-transform:rotate(0deg) translate(0, -130%);
                        -webkit-animation-delay: 0s;
                      }    
                      
                      div.spinner div.bar2 {
                        -webkit-transform:rotate(30deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.9167s;
                      }
                      
                      div.spinner div.bar3 {
                        -webkit-transform:rotate(60deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.833s;
                      }
                      div.spinner div.bar4 {
                        -webkit-transform:rotate(90deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.7497s;
                      }
                      div.spinner div.bar5 {
                        -webkit-transform:rotate(120deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.667s;
                      }
                      div.spinner div.bar6 {
                        -webkit-transform:rotate(150deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.5837s;
                      }
                      div.spinner div.bar7 {
                        -webkit-transform:rotate(180deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.5s;
                      }
                      div.spinner div.bar8 {
                        -webkit-transform:rotate(210deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.4167s;
                      }
                      div.spinner div.bar9 {
                        -webkit-transform:rotate(240deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.333s;
                      }
                      div.spinner div.bar10 {
                        -webkit-transform:rotate(270deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.2497s;
                      }
                      div.spinner div.bar11 {
                        -webkit-transform:rotate(300deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.167s;
                      }
                      div.spinner div.bar12 {
                        -webkit-transform:rotate(330deg) translate(0, -130%); 
                        -webkit-animation-delay: -0.0833s;
                      }
                }
                iframe{
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    position: relative;
                }
            }
            .page-preview-mobile::-webkit-scrollbar {
                width: 0.5em;
            }   
            .page-preview-mobile::-webkit-scrollbar-thumb {
                background-color: rgba(128, 128, 128, 0.5);
                border-radius: 5px;
            }
        }
        .dashboard-section::-webkit-scrollbar {
            width: 0.5em;
        }   
        .dashboard-section::-webkit-scrollbar-thumb {
            background-color: rgba(128, 128, 128, 0.5);
            border-radius: 5px;
        }
    
    }
    
}

.add-block-modal-body-main {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.add-block-modal-body-main::-webkit-scrollbar {
    width: 0em;
    border-radius: 10px;
  }
  
  .add-block-modal-body-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .add-block-modal-body-main::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 10px;
  }
.new-block-main-div {
    width: 100%;
    flex-wrap: wrap;
}
.new-block-div {
    padding: 6px;
    cursor: pointer;
    transition: 0.1s all ease;
    -webkit-transition: 0.1s all ease;
    -moz-transition: 0.1s all ease;
    -o-transition: 0.1s all ease;
    -ms-transition: 0.1s all ease;
    flex-basis: 33%;
    &:hover{
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -o-transform: scale(1.05);
        -ms-transform: scale(1.05);
    }
}


.link-card {
    width: 220px;
    /* height: 180px; */
    /* padding: 10px 10px; */
    padding-left: 5px;
    margin: 0 auto;
    .add-image{
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0px 0px 15px rgba(21, 16, 54, 0.1);
        position: absolute;
        z-index: 1;
        margin-top: -10px;
        right:10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .link-btn {
        position: relative;
        white-space: normal;
        word-wrap: break-word;
        padding: 1rem 4.5rem;
        font-size: 1.1rem;
        box-shadow: 0px 0px 15px #00008024;
        border: 0;
        transition: opacity 0.3s, background 0.3s;
        border-radius: 0;
        border-radius: 25px;
        padding: 10px;
        min-height: 290px;
        max-height: 290px;
        .link-btn-image-wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
            max-width: 45px;
            max-height: 45px;
            position: absolute;
            top: 50%;
            margin-top: -22.5px;
            margin-left: -4.1rem;
        }
        .link-img-div2 {
            width: 100%;
            /* height: 151px; */
            background-color: #E9E9E9;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            .link-btn-image2 {
                width: 100%;
                background: url('../img/modals/default-thumbnail.png') no-repeat center;
                background-size: cover;
                padding-top: 56.25%;
            }
        }
        .link-title2 {
            // font-size: 14px;
            margin: 15px 0;
            font-weight: 600;
            text-align: left;
            margin-bottom: 7px;
            color: $theme-text-color;
        }
        .link-description2 {
            margin-top: 0;
            margin-bottom: 0;
            color: #404762;
            font-size: 14px;
            line-height: 15px;
            text-align: left;
            max-height: 30px;
            /* background: red; */
            overflow: hidden;
            overflow-wrap: anywhere;
        }
        .div-block-3422 {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 90%;
            margin-top: 0;
            padding-top: 9px;
            padding-bottom: 9px;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 14px;
            background-color: #F6F6F6;
            position: absolute;
            bottom: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 600;
        }
    }
    
}
.up-in-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 34px;
    color: $theme-text-color;
    label {
      display: inline-block;
      width: 60px;
      text-align: center;
      font-family: "Quicksand", sans-serif;
      font-size: 0.8rem;
      padding: 6px 16px;
      text-align: center;
      width: 50%;
      float: left;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .up-in-toggle.bg-white{
    
    box-shadow: 0px 0px 14px rgb(57 58 90 / 10%);
    input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        &:checked + label {
          background: #f1f1f1;
          font-weight: 500;
        }
    }
  }
  .up-in-toggle.bg-f1f1f1{
    input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
        &:checked + label {
          background: #FFFFFF;
          font-weight: 500;
        }
    }
  }
  
  .up-in-toggle label:first-of-type {
    border-radius: 20px;
  }
  
  .up-in-toggle label:last-of-type {
    border-radius: 20px;
  }
  .product-custom-input{
    border-radius: 12px !important;
    border: 1px solid #E1E1E1 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 5px;
    .select {
        position: relative;
        width: 250px;
        border-radius: 12px !important;
      }
      .select .selectBtn {
        background: var(--bg1);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        cursor: pointer;
        position: relative;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }
      .select .selectBtn:after {
        content: "";
        position: absolute;
        top: 45%;
        left: 130px;
        font-weight: bold;
        width: 6px;
        height: 6px;
        -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
        border-right: 2px solid #666;
        border-bottom: 2px solid #666;
        transition: 0.2s ease;
      }
      .select .selectBtn.toggle {
        border-radius: 3px 3px 0 0;
      }
      .select .selectBtn.toggle:after {
        -webkit-transform: translateY(-50%) rotate(-135deg);
                transform: translateY(-50%) rotate(-135deg);
      }
      .select .selectDropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        border-radius: 0 0 3px 3px;
        overflow: hidden;
        background: var(--bg1);
        border: 1px solid #E1E1E1 !important;
        z-index: 1;
        background: #fff;
        -webkit-transform: scale(1, 0);
                transform: scale(1, 0);
        -webkit-transform-origin: top center;
                transform-origin: top center;
        visibility: hidden;
        transition: 0.2s ease;
      }
      .select .selectDropdown .option {
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        border-bottom: 1px solid #f8f8f8 !important;
      }
      .select .selectDropdown .option:hover {
        background: #f8f8f8;
      }
      .select .selectDropdown.toggle {
        visibility: visible;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
      }
      
  }
  .link-switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 22px;
}
.link-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.link-switch .slider.round {
    border-radius: 34px;
}
.link-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.link-switch .slider.round:before {
    border-radius: 50%;
}
.link-switch .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.link-switch input:checked + .slider {
    background-color: #FF1451;
}
.link-switch input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
  .customize-page-desktop{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 90vh;
    padding: 0;
    .left-bar{
        width: 25%;
        height: inherit;
        background-color: #FAFAFA;
        padding: 25px 15px;
        .customize-page-menu-link{
            width: 100%;
            padding: 12px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: start;
            transition: 0.2s all ease;
            -webkit-transition: 0.2s all ease;
            -moz-transition: 0.2s all ease;
            -o-transition: 0.2s all ease;
            -ms-transition: 0.2s all ease;
            cursor: pointer;
            margin-bottom: 5px;
            &:hover{
                background-color: #F1F1F1;   
            }
            img{
                box-shadow: 0px 4px 20px rgba(103, 101, 122, 0.19);
                border-radius: 8px;
            }
        }
        .customize-page-menu-link.active{
            background-color: #F1F1F1;
        }
    }
    .main-section{
        width: 75%;
        height: inherit;
    }
    .close-btn{
        position: absolute;
        right: 20px;
        top: 15px;
    }
  }
  .customize-page-mobile{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    height: 90vh;
    background-color: white;
    padding: 0;
    .close-btn{
      position: absolute;
      right: 20px;
      top: 15px;
    }
    
  }
  .customize-page-main{
    width: 100%;
    height: 100%;
    padding: 20px 30px;
    padding-bottom: 0;
    label{
        font-weight: 500 !important;
    }
    .input-group-text{
        background-color: #F7F7F8 !important;
    }
    section{
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        padding-bottom: 60px;
    }
    section::-webkit-scrollbar {
        width: 0em;
    }   
    section::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
    }
    .user-pp{
        border-radius: 70px;
        border:1.5px dashed #6B6B6B;
        padding:5px;
        width: 120px;
        height: 120px;
        cursor: pointer;
    }
    .theme-main-right {
        display: flex;
        flex-wrap: wrap;
      }
      .theme-main-right .theme-selection-div {
        flex-basis: 20%;
      }
      .theme-selection-div {
        padding: 5px;
      }
      .theme-selection-indiv {
        /* background-color: #F1F1F1; */
        border-radius: 20px;
        /* height: 250px; */
        padding: 16px 0px;
        border: 2px solid transparent !important;
      }
      .themes-selection div div span {
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        /* margin-right: 10px; */
        /* height: 250px; */
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        font-weight: 500;
        text-align: center;
        color: $theme-text-color;
      }
      .themes-selection div span img {
        width: 90px !important;
      }
      .themes-selection div span small {
        margin-top: 10px;
      }
      .theme-selection-indiv.selected-theme {
        border: 2px solid #FF4566 !important;
      }
      .custom-domain-input-group{
        width: 100%;
        padding:7px 15px;
        background-color: #F7F7F8;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            width: 180px !important;
            // flex-basis: 30%;
        }
      }
      .dropzone-wrapper5{
        height: 130px !important;
        .dropzone-desc{
            top: 15% !important;
        }
      }
      .integration-card{
        width: 200px;
        height: 200px;
        border-radius: 20px;
        border:1px solid #E8E9EA;
        padding:15px;
        .google-analytics-btn{
            background-color: #F9AB00;
        }
        .facebook-pixel-btn{
            background-color: #1877F2;
        }
        .mailchimp-btn{
            background-color: #FFDC00;
        }
        .convertkit-btn{
            background-color: #FC6B72;
        }
        .sendinblue-btn{
            background-color: #0092FF;
        }
  }
  .customize-page-main.accordion-body{
    section{
      padding-bottom: 10px !important;
    }
  }      
}
.marquee {
    width: 100%;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    animation: marquee 7s ease-in-out alternate infinite;
}

.marquee-reverse {
    width: 100%;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee-reverse p {
    display: inline-block;
    transform: translate(-100%,0);
    animation: marquee-reverse 7s ease-in-out alternate infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

@keyframes marquee-reverse {
    0%   { transform: translate(-100%, 0); }
    100% { transform: translate(0, 0); }
}