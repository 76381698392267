$border-color: rgba(12, 2, 43, 0.1);
$theme-gray: #999999;
$theme-blue: #4397F7;
$theme-primary: #FF1451;
$theme-primary-dark: #CF2551;
$theme-primary-darker: #e40641;
$theme-primary-lighter: rgba(228, 6, 65, 0.5);
$theme-primary-variant: #FF2020;
$theme-primary-bg: #FFDFDF;
$theme-text-color: #5B5B5B;
$theme-text-lightcolor: #6B6B6B;